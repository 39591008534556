import useStyles from "./formStyles";
import imageGmail from "../../../../assets/loginPlayerForm/gmail.svg";
import TextInput from "../../../../components/TextInput/TextInput";
import { Button } from "@material-ui/core";
import { useState } from "react";

const Form = ({ onSubmit, onGoogleLogin }) => {
  const [formState, setFormState] = useState({ name: "" });

  const classesForm = useStyles({ formSize: "little" });

  const onSubmitHandler = (e) => {
    e.preventDefault();
    onSubmit(formState);
  };

  return (
    <form onSubmit={onSubmitHandler} className={classesForm.form}>
      <div className="title">
        <h4>Log in</h4>
      </div>
      <Button onClick={onGoogleLogin} classes={{ root: classesForm["form__mail-button"] }}>
        <img src={imageGmail} alt="Gmail" />
        <h6 className={classesForm["text-mail-botton"]}>
          Log in with Google
        </h6>
      </Button>
      <div className={classesForm["form__line"]}></div>
      <TextInput
        name={"My session name"}
        stateName="name"
        value={formState.name}
        placeholder=""
        setValue={setFormState}
      />
      <div className={classesForm["form__submit-button"]}>
        <Button type="submit" value="Let's vote">
          <h6>Let's vote</h6>
        </Button>
      </div>
    </form>
  );
};

export default Form;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  'issue-card': {
    marginBottom: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '1.3rem',
    width: '100%',
    background: (edit) =>
      edit
        ? 'linear-gradient(0deg, rgba(0, 125, 255, 0.08), rgba(0, 125, 255, 0.08)), #EAECFE'
        : '#FFFFFF',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',

    '& .MuiOutlinedInput-multiline': {
      padding: '0',
    },
  },

  'issue-card-head': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },

  'issue-card-icon-button': {
    color: theme.palette.primary.light,
  },

  'issue-card-clickup-logo': { marginLeft: '10px' },

  'issue-card-left-items': {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    '& img': {
      width: '1em',
      height: '1em',
    },
  },

  'issue-card-right-items': {
    display: 'flex',
    alignItems: 'center',
  },

  'issue-card-title': {
    fontFamily: 'Open Sans',
    color: 'rgba(0, 0, 0, 0.54)',
  },

  'issue-text-field': {
    fontFamily: 'Open Sans',
    color: '#383838',
    fontSize: '0.9rem',
    padding: '0.5rem 0.7rem',
  },

  'multiline-buttons-container': {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '12px',
    alignItems: 'center',
    '& button': {
      marginRight: '5px',
    },
  },

  'multiline-card-list-item-text': { overflowWrap: 'break-word' },

  '@media screen and (max-width: 480px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 480px) and (max-width: 768px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 768px) and (max-width: 992px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 992px) and (max-width: 1200px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1200px) and (max-width: 1440p)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1440p)': {
    foo: 'bar',
  },
}));

export default useStyles;

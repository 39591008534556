import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Background from "../../components/Background/Background";
import { CLICKUP_AUTH } from "../../services/clickup";
import { useMutation } from "@apollo/client";

const ClickUpAuth = () => {
  const location = useLocation();
  const [clickupAuthCode] = useMutation(CLICKUP_AUTH, {
    onError: (error) => {
      const event = new CustomEvent("clickup message", {
        detail: error,
      });
      window.opener.dispatchEvent(event);
      window.close();
    },
    onCompleted: ({ clickupAuth }) => {
      const event = new CustomEvent("clickup message", {
        detail: clickupAuth.isLoggedInClickup,
      });
      window.opener.dispatchEvent(event);
      window.close();
    },
  });

  useEffect(() => {
    if (location.search) {
      clickupAuthCode({
        variables: { authCode: location.search.substring(6) },
      });
    }
  }, [location.search]);

  return <Background></Background>;
};

export default ClickUpAuth;

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useMemo, useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import GeneralButton from '../../../GeneralButton/GeneralButton';
import useStyles from '../../headerStyles';
import { routes } from '../../../../constants/routes';
import { useModals } from '../../../../contexts/modalsContext';
import { useSession } from '../../../../contexts/sessionContext';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';

const PlanningPokerButtons = () => {
  const classes = useStyles();
  const {
    sessionState: { _id: sessionId },
  } = useSession();
  const {
    player: {
      permissions: { manageIssues },
    },
  } = usePlayerContext();
  const history = useHistory();
  const { toggleDrawer } = useModals();
  const [snackbar, setSnackbar] = useState(false);
  const onClose = () => setSnackbar(false);
  const URL = window.location.href;

  const redirectToDashboard = () => {
    const path = generatePath(routes.DASHBOARD, {
      sessionId,
    });
    history.push({
      pathname: path,
      prevPath: history.location.pathname,
    });
  };

  const redirectToGameSettings = () => {
    const settingsPath = generatePath(routes.GAME_SETTINGS, {
      sessionId,
    });
    history.push({
      pathname: settingsPath,
      prevPath: history.location.pathname,
    });
  };

  const copyToClipboard = () => {
    setSnackbar(true);
    navigator.clipboard.writeText(`${URL}`);
  };

  const buttons = useMemo(
    () => [
      {
        label: 'Dashboard',
        onClick: redirectToDashboard,
      },
      {
        label: 'Invite',
        onClick: copyToClipboard,
        icon: <PersonAddIcon style={{ color: '#00B0FF' }} />,
      },
      {
        label: 'Stories',
        onClick: toggleDrawer,
      },
    ],
    [toggleDrawer]
  );

  return (
    <>
      {manageIssues && (
        <SettingsIcon
          onClick={redirectToGameSettings}
          alt="game settings"
          className={classes.settings}
        />
      )}
      {buttons.map((element) => (
        <div key={element.label} className={classes.header__buttonMargin}>
          <GeneralButton
            variant="contained"
            color="primary"
            size="large"
            label={element.label}
            startIcon={element.icon}
            onClick={element.onClick}
          />
        </div>
      ))}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar}
        onClose={onClose}
        autoHideDuration={5000}
      >
        <Alert onClose={onClose} severity="success">
          {`Session's URL copied to clipboard`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PlanningPokerButtons;

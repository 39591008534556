import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { updatedAwsConfig } from './helpers/AmplifyConfig';
import ignoreErrorLogs from './helpers/ignoreErrorLogs';
import App from './App';
import './reset.css';
import './index.css';

console.error = ignoreErrorLogs;

Amplify.configure(updatedAwsConfig);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

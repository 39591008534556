import React from 'react';
import { useHistory, generatePath, Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Header from '../../components/Header/Header';
import CreateSessionForm from './components/CreateSessionForm/CreateSessionForm';
import { validateName } from '../../helpers/Validate';
import { usePlayerContext } from '../../hooks/usePlayerContext';
import { routes } from '../../constants/routes';
import Background from '../../components/Background/Background';
import useStyles from '../defaultPageStyles';
import { useSnackbarError } from '../../hooks/useSnackbarError';
import { CREATE_SESSION } from '../../services/session';

const CreateSession = () => {
  const classes = useStyles();
  const history = useHistory();
  const { player } = usePlayerContext();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const [createSessionMutation, { loading }] = useMutation(CREATE_SESSION, {
    onCompleted: ({ createSession }) => {
      const path = generatePath(routes.PLANNING_POKER_SESSION, {
        sessionId: createSession._id,
      });
      history.push(path);
    },
    onError: (error) => setErrorSnackbar(error?.message),
  });

  const onSubmitHandler = async (sessionForm) => {
    if (!validateName(sessionForm.name)) {
      setErrorSnackbar('Invalid session name');
      return;
    }

    const timer = { time: '02:00', state: 'inactive', date: 0, switch: true };
    const sessionBody = { ...sessionForm, timer };
    await createSessionMutation({ variables: { sessionBody } });
  };

  return player._id ? (
    <>
      <Background>
        <Header />
        <div className={classes.mainContainer}>
          <CreateSessionForm onSubmit={onSubmitHandler} loading={loading} />
        </div>
      </Background>
      {showErrorSnackbar()}
    </>
  ) : (
    <Redirect
      to={{
        pathname: routes.LOGIN_FORM,
        state: {
          type: 'error',
          message: 'You must be logged in to create a session.',
          prevPath: routes.CREATE_SESSION,
        },
      }}
    />
  );
};

export default CreateSession;

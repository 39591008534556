/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTimer } from 'react-timer-hook';
import {
  MenuItem,
  Menu,
  TextField,
  Button,
  Switch,
  Tooltip,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import StopIcon from '@material-ui/icons/Stop';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import useSound from 'use-sound';
import { UPDATE_TIMER } from '../../../../services/timer';
import { useSession } from '../../../../contexts/sessionContext';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';
import timerSound from '../../../../assets/sounds/timer.mp3';
import Rocket from '../../../../assets/rocket.svg';
import { UPDATE_SESSION } from '../../../../services/session';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import Icon from './Icon';
import useStyles from './timerStyles';
import { useTimerContext } from '../../../../contexts/timerContext';

const classNames = require('classnames');

const stringToDate = (time, date) => {
  const [mins, secs] = time.split(':');
  const offsetTime = mins * 60 + parseInt(secs);
  const baseTime = new Date(date);
  baseTime.setSeconds(baseTime.getSeconds() + offsetTime);
  return baseTime;
};

const padZero = (x) => (x < 10 ? `0${x}` : `${x}`);

const buildTimeString = (mins, secs) => {
  const minsFormatted = padZero(mins);
  const secsFormatted = padZero(secs);

  return `${minsFormatted}:${secsFormatted}`;
};

const addMinute = (mins, secs) =>
  buildTimeString(parseInt(mins) + 1, parseInt(secs));

const Timer = () => {
  const { showErrorSnackbar, setErrorSnackbar } = useSnackbarError();
  const classes = useStyles();
  const {
    sessionState: { _id: sessionId, timer },
  } = useSession();
  const {
    player: {
      permissions: { revealCards },
    },
  } = usePlayerContext();
  const [updateTimer] = useMutation(UPDATE_TIMER, {
    onError: (error) => setErrorSnackbar(error?.message),
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    onError: (error) => setErrorSnackbar(error?.message),
  });

  const { timerState, dispatch } = useTimerContext();

  const [popUp, setPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [play] = useSound(timerSound);
  const inactive = timer?.state === 'inactive';
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 120);

  const handleSwitchState = () => dispatch({ switch: !timer?.switch });

  const revealVotesHandler = async () => {
    const variables = { sessionId, sessionBody: { revealed: true } };
    await updateSession({ variables });
  };

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: () => {
      play();
      dispatch({ state: 'inactive' });
      setPopUp(true);
      timer?.switch && revealVotesHandler();
    },
  });

  const onChangeHandler = (e) => {
    dispatch({ input: e.target.value });
  };

  const toggleAnchor = (event) =>
    anchorEl ? setAnchorEl(null) : setAnchorEl(event?.currentTarget);

  const closeMenu = (event) => {
    toggleAnchor(event);
    setIsMenuOpen(false);
  };
  const openMenu = (event) => {
    toggleAnchor(event);
    setIsMenuOpen(true);
  };

  const handleTimerClick = (event) => openMenu(event);

  const startHandler = () => {
    isMenuOpen && closeMenu();
    dispatch({
      time: timerState.input,
      state: 'active',
      date: Date.now(),
    });
  };

  const continueHandler = () => {
    dispatch({
      state: 'active',
      date: Date.now(),
    });
  };

  const addMinuteHandler = () => {
    const [minInput, secInput] = timerState.input.split(':');
    const time = inactive
      ? addMinute(minInput, secInput)
      : addMinute(minutes, seconds);

    dispatch({
      time,
      date: Date.now(),
      input: addMinute(minInput, secInput),
    });
  };

  const cancelHandler = () => {
    dispatch({
      time: timerState.input,
      state: 'inactive',
      date: 0,
    });
  };

  const pauseHandler = () => {
    dispatch({
      time: buildTimeString(minutes, seconds),
      state: 'pause',
      date: Date.now(),
    });
  };

  const handleEnterKey = (event) =>
    event.key === 'Enter' && startHandler(event);

  useEffect(() => {
    async function update() {
      await updateTimer({
        variables: {
          sessionId,
          timerBody: {
            time: timerState.time,
            state: timerState.state,
            date: timerState.date,
            switch: timerState.switch,
          },
        },
      });
    }
    timerState?.time && sessionId && update();
  }, [
    timerState?.time,
    timerState?.state,
    timerState?.date,
    timerState?.switch,
    sessionId,
  ]);

  useEffect(() => {
    if (timer) {
      const newExpiryTimestamp = stringToDate(timer.time, timer.date);
      const expired = new Date(Date.now()) > newExpiryTimestamp;
      switch (timer.state) {
        case 'inactive':
          restart(newExpiryTimestamp, false);
          dispatch({ input: timer.time });
          break;
        case 'active':
          if (expired) {
            dispatch({
              time: timer.time,
              state: 'inactive',
              date: timer.date,
              input: timer.time,
            });
            break;
          }
          restart(newExpiryTimestamp, true);
          break;
        case 'pause':
          restart(stringToDate(timer.time, Date.now()), false);
          break;
        default:
          break;
      }
    }
  }, [timer]);

  useEffect(() => {
    setTimeout(() => setPopUp(false), 2500);
  }, [popUp]);

  const iconButtons = () => {
    return (
      revealCards && (
        <>
          {isRunning ? (
            <Icon onclick={pauseHandler} icon={<PauseIcon />} />
          ) : (
            <Icon onclick={continueHandler} icon={<PlayArrowIcon />} />
          )}
          <Icon onclick={cancelHandler} icon={<StopIcon />} />
        </>
      )
    );
  };

  return (
    <>
      {popUp && (
        <div className={classes['rocket-container']}>
          <div className={classes.rocket}>
            <img src={Rocket} alt="Rocket" />
          </div>
          <h5>Time&apos;s up!</h5>
        </div>
      )}
      <Button
        className={classNames({
          [`${classes['button-primary']}`]: inactive,
          [`${classes['button-blinking']}`]:
            minutes === 0 && seconds <= 10 && isRunning,
          [`${classes['button-secondary']}`]:
            !inactive && (minutes !== 0 || seconds > 10 || !isRunning),
        })}
        disabled={!revealCards}
        size="large"
        onClick={handleTimerClick}
        aria-controls="simple-menu"
      >
        {inactive ? timerState.input : buildTimeString(minutes, seconds)}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={isMenuOpen}
        onClose={(event) => closeMenu(event)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className={classes['timer-menu']}
        PaperProps={{ style: { width: '165px' } }}
      >
        <InputMask
          mask="59:59"
          maskplaceholder="00:00"
          maskChar="0"
          value={timerState.input}
          onChange={onChangeHandler}
          formatChars={{ 9: '[0-9]', 5: '[0-5]' }}
        >
          {(inputProps) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
              label="Time"
              variant="outlined"
              size="small"
              className={classes['timer-menu-textfield']}
              onKeyPress={handleEnterKey}
            />
          )}
        </InputMask>
        <Tooltip
          classes={{ tooltip: classes['timer-tooltip'] }}
          title="Timer will automatically start when choosing to vote an issue and cards
          will be shown after time runs out"
          placement="right-start"
        >
          <MenuItem onClick={handleSwitchState}>
            <Switch checked={timer?.switch} size="small" />
            Auto
          </MenuItem>
        </Tooltip>
        <MenuItem onClick={addMinuteHandler}>
          <AlarmAddIcon
            fontSize="small"
            className={classes['timer-menu-icon']}
          />
          Add 1 Minute
        </MenuItem>
      </Menu>
      {iconButtons()}
      {showErrorSnackbar()}
    </>
  );
};

export default Timer;

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useSession } from '../../../../contexts/sessionContext';
import { useConsensus } from '../../../../hooks/useConsensus';
import ConfettiLabel from './ConfettiLabel';
import ResultsDescription from './ResultsDescription';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import { UPDATE_ISSUE } from '../../../../services/issue';

const Consensus = () => {
  const {
    sessionState: { _id: sessionId, currentIssue, strategy },
  } = useSession();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const { checkConsensus } = useConsensus();
  const [updateIssue] = useMutation(UPDATE_ISSUE, {
    onError: (error) => setErrorSnackbar(error?.message),
  });
  const { result, agreement, agreementLevel } = checkConsensus();

  useEffect(() => {
    if (agreementLevel === 'high') {
      const variables = {
        sessionId,
        issueId: currentIssue,
        issueBody: { result },
      };
      updateIssue({ variables });
    }
  }, [agreementLevel, currentIssue, result, sessionId, updateIssue]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      {agreementLevel === 'high' && (
        <Grid item>
          <ConfettiLabel />
        </Grid>
      )}
      <Grid item>
        <ResultsDescription
          strategy={strategy}
          result={result}
          agreement={agreement}
          agreementLevel={agreementLevel}
        />
      </Grid>
      {showErrorSnackbar()}
    </Grid>
  );
};

export default React.memo(Consensus);

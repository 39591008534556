import { gql } from "@apollo/client";

export const CLICKUP_AUTH = gql`
  mutation clickupAuth($authCode: String!) {
    clickupAuth(authCode: $authCode) {
      name
      isLoggedInClickup
    }
  }
`;

export const GET_CLICKUP_SPACES = gql`
  query getClickupSpaces {
    getClickupSpaces {
      id
      name
    }
  }
`;

export const GET_CLICKUP_FOLDERS = gql`
  query getClickupFolders($spaceId: String!) {
    getClickupFolders(spaceId: $spaceId) {
      id
      name
    }
  }
`;

export const GET_CLICKUP_FOLDERLESS_LISTS = gql`
  query getClickupFolderlessLists($spaceId: String!) {
    getClickupFolderlessLists(spaceId: $spaceId) {
      id
      name
    }
  }
`;

export const GET_CLICKUP_LISTS = gql`
  query getClickupLists($folderId: String!) {
    getClickupLists(folderId: $folderId) {
      id
      name
    }
  }
`;

export const GET_CLICKUP_CUSTOM_FIELDS = gql`
  query getClickupCustomFields($listId: String!) {
    getClickupCustomFields(listId: $listId) {
      id
      name
    }
  }
`;

export const GET_CLICKUP_STATUSES = gql`
  query getClickupStatuses($listId: String!) {
    getClickupStatuses(listId: $listId) {
      name
    }
  }
`;

export const IMPORT_CLICKUP_ISSUES = gql`
  mutation importClickupIssues(
    $sessionId: String!
    $fieldId: String!
    $listId: String!
    $statuses: [String!]!
  ) {
    importClickupIssues(
      sessionId: $sessionId
      fieldId: $fieldId
      listId: $listId
      statuses: $statuses
    )
  }
`;

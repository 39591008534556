import * as React from 'react';

const defaultValue = {
  issueDrawer: false,
  importIssuesDialog: false,
  importModal: true,
  deleteAllIssuesDialog: false,
  deleteIssueDialog: false,
  cancelVoteDialog: false,
  deleteSessionDialog: false,
};
export const ModalsContext = React.createContext(null);

export const ModalsProvider = ({ children }) => {
  const [modalsState, setModalsState] = React.useState(defaultValue);

  const toggle =
    (modal) =>
    (event = {}) => {
      if (
        event?.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      )
        return;

      setModalsState({ ...modalsState, [modal]: !modalsState[modal] });
    };

  const toggleDrawer = toggle('issueDrawer');
  const toggleImportIssuesDialog = toggle('importIssuesDialog');
  const toggleDeleteIssuesDialog = toggle('deleteAllIssuesDialog');
  const toggleDeleteIssueDialog = toggle('deleteIssueDialog');
  const toggleCancelVoteDialog = toggle('cancelVoteDialog');
  const toggleDeleteSessionDialog = toggle('deleteSessionDialog');
  const toggleModal = toggle('importModal');

  const value = {
    modalsState,
    toggleDrawer,
    toggleImportIssuesDialog,
    toggleDeleteIssuesDialog,
    toggleDeleteIssueDialog,
    toggleCancelVoteDialog,
    toggleDeleteSessionDialog,
    toggleModal,
  };

  return (
    <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>
  );
};

export const useModals = () => {
  const value = React.useContext(ModalsContext);
  if (value === null) {
    throw new Error('useModals have to be used within a ModalsProvider');
  }
  return value;
};

export const routes = {
  HOME: '/home',
  LOGIN_FORM: '/login-form',
  CREATE_SESSION: '/create-session',
  PLANNING_POKER_SESSION: '/planning-poker-session/:sessionId',
  DASHBOARD: '/planning-poker-session/:sessionId/dashboard',
  SESSIONS_HISTORY: '/sessions-history',
  HISTORY_DASHBOARD: '/sessions-history/:sessionId/dashboard',
  LOGOUT: '/logout',
  LOGIN: '/login',
  GAME_SETTINGS: '/planning-poker-session/:sessionId/game-settings',
  CLICKUP_AUTH: '/clickup-auth',
  ERROR: '*',
};

export default routes;

import { gql } from '@apollo/client';

export const SESSION_SUBSCRIPTION = gql`
  subscription OnSessionUpdate($sessionId: String!) {
    session(sessionId: $sessionId) {
      _id
      name
      players {
        _id
        name
        email
        active
        isObserver
        pictureURL
        permissions {
          manageIssues
          revealCards
        }
      }
      issues {
        _id
        title
        result
        clickupTaskId
        votes {
          _id
          playerId
          value
          time
        }
      }
      creator {
        _id
        name
        email
        active
        isObserver
        permissions {
          manageIssues
          revealCards
        }
      }
      currentIssue
      revealed
      scale
      timer {
        time
        state
        date
        switch
      }
      strategy
      showCards
      clickupResultFieldId
    }
  }
`;

export const UPDATE_SESSION = gql`
  mutation updateSession(
    $sessionId: String!
    $sessionBody: UpdateSessionInput!
    $playersPermissions: [UpdateSessionPlayersPermissionsInput!]
  ) {
    updateSession(
      sessionId: $sessionId
      sessionBody: $sessionBody
      playersPermissions: $playersPermissions
    ) {
      _id
      name
    }
  }
`;

export const DELETE_ALL_ISSUES = gql`
  mutation deleteAllIssues($sessionId: String!) {
    deleteAllIssues(sessionId: $sessionId) {
      _id
      name
      issues {
        _id
      }
    }
  }
`;

export const GET_SESSION = gql`
  query getSession($sessionId: String!) {
    getSession(sessionId: $sessionId) {
      _id
      name
      players {
        _id
        name
        email
        active
        isObserver
        pictureURL
        permissions {
          manageIssues
          revealCards
        }
      }
      issues {
        _id
        title
        result
        clickupTaskId
        votes {
          _id
          playerId
          value
          time
        }
      }
      creator {
        _id
        name
        email
        active
        isObserver
        permissions {
          manageIssues
          revealCards
        }
      }
      currentIssue
      revealed
      scale
      timer {
        time
        state
        date
        switch
      }
      strategy
      showCards
      clickupResultFieldId
    }
  }
`;

export const CREATE_SESSION = gql`
  mutation createSession($sessionBody: CreateSessionInput!) {
    createSession(sessionBody: $sessionBody) {
      _id
    }
  }
`;

export const DELETE_SESSION = gql`
  mutation DeleteSession($sessionId: String!) {
    deleteSession(sessionId: $sessionId) {
      id: _id
      name
    }
  }
`;

export const GET_SESSIONS = gql`
  query getSessions {
    getSessions {
      id: _id
      name
      creator {
        id: _id
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const UPDATE_ISSUE = gql`
  mutation updateIssue(
    $sessionId: String!
    $issueId: String!
    $issueBody: UpdateIssueInput
    $deleteVotes: Boolean
  ) {
    updateIssue(
      sessionId: $sessionId
      issueId: $issueId
      issueBody: $issueBody
      deleteVotes: $deleteVotes
    ) {
      _id
    }
  }
`;

export const DELETE_ISSUE = gql`
  mutation deleteIssue($sessionId: String!, $issueId: String!) {
    deleteIssue(sessionId: $sessionId, issueId: $issueId) {
      _id
    }
  }
`;

export const CREATE_ISSUE = gql`
  mutation createIssue($sessionId: String!, $issueBody: CreateIssueInput!) {
    createIssue(sessionId: $sessionId, issueBody: $issueBody) {
      _id
    }
  }
`;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'loading-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    paddingBottom: '12%',
    zIndex: '3',
  },
  loading: {
    width: '17rem',
    height: '8rem',
    'text-align': 'center',
    overflow: 'hidden',
    '& p': {
      'font-family': 'Roboto',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '12px',
      'line-height': '266%',
      'letter-spacing': '1px',
      'text-align': 'center',
      color: '#FFFFFF',
      marginTop: '8px',
    },
  },

  'loading-cloud-big': {
    height: '1.3rem',
    animation: '$cloudFloating 1.8s ease-in infinite ',
  },

  'loading-cloud-small': {
    height: '0.8rem',
    marginBottom: '1.8rem',
    animation: '$cloudFloating 2.5s ease-in infinite ',
  },

  'loading-rocket': {
    height: '4.18rem',
    animation: '$rocketFloating 1s ease-in-out infinite alternate',
  },

  progress: {
    height: '1.813rem',
    width: '17rem',
    backgroundColor: 'rgb(0,0,0,0)',
    border: '2px solid #FFFFFF',
    borderRadius: '4px',
  },

  '@keyframes rocketFloating': {
    '0%': {
      transform: 'rotate(37deg) translate(27%, 5%) ',
    },
    '100%': {
      transform: 'rotate(37deg) translate(15%, -10%) ',
    },
  },

  '@keyframes cloudFloating': {
    '0%': {
      transform: 'translatex(16rem) ',
    },
    '100%': {
      transform: 'translatex(-13rem) ',
    },
  },
});

export default useStyles;

import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { CREATE_VOTE, UPDATE_VOTE } from '../../../../services/vote';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import { useSession } from '../../../../contexts/sessionContext';
import { scales } from '../../../../constants/scales';
import GeneralCardsPanel from '../../../../components/GeneralCardsPanel/GeneralCardsPanel';
import ActionButtons from './ActionButtons';

const PlanningPokerBodyBottom = () => {
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const onError = (error) => setErrorSnackbar(error?.message);
  const { player } = usePlayerContext();
  const {
    sessionState: {
      _id: sessionId,
      currentIssue: currentIssueId,
      issues,
      scale: scaleName,
      revealed,
      currentIssueIndex,
    },
  } = useSession();
  const [addVote, { data: voteData }] = useMutation(CREATE_VOTE, { onError });
  const [updateVote] = useMutation(UPDATE_VOTE, { onError });
  const [cardSelected, setCardSelected] = useState('');

  const scale = scales[scaleName];
  const isNotObserver = !player?.isObserver;

  useEffect(() => {
    if (cardSelected && currentIssueId && !revealed) {
      const playerVoted = issues?.[currentIssueIndex]?.votes?.find(
        (vote) => vote.playerId === player._id
      );
      if (!playerVoted) {
        addVote({
          variables: {
            sessionId,
            issueId: currentIssueId,
            voteBody: { value: cardSelected },
          },
        });
      } else if (voteData?.createVote?._id) {
        updateVote({
          variables: {
            sessionId,
            issueId: currentIssueId,
            voteId: voteData.createVote._id,
            voteBody: { value: cardSelected },
          },
        });
      }
    }
  }, [cardSelected]);

  const getButtonsOrHideCards = () => {
    if (revealed) {
      return <ActionButtons />;
    }

    if (isNotObserver && !revealed && currentIssueId) {
      return (
        <GeneralCardsPanel
          scale={scale}
          currentIssue={currentIssueId}
          cardSelected={cardSelected}
          setCardSelected={setCardSelected}
          variant="planning"
        />
      );
    }

    return null;
  };

  return (
    <Box minHeight="25%">
      {getButtonsOrHideCards()}
      {showErrorSnackbar()}
    </Box>
  );
};

export default React.memo(PlanningPokerBodyBottom);

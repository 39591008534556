import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  "text-field": {
    '& label.Mui-focused': {
      color: 'grey',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'grey',
      },
    },
  }
});

export default useStyles;
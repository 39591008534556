import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import useStyles from './submitFormButtonStyles';

const SubmitFormButton = ({ loading, label }) => {
  const classes = useStyles();

  return (
    <div key="98" className={classes['form__submit-button']}>
      <Button
        key="5"
        type="submit"
        value="Next"
        color="secondary"
        variant="contained"
        size="large"
      >
        {loading ? <CircularProgress size="1.50rem" thickness={5} /> : label}
      </Button>
    </div>
  );
};

export default SubmitFormButton;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  header: {
    width: '100%',
    height: '10%',
    minHeight: '140px',
    padding: '2%',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
    overflowX: 'auto',
  },

  left: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },

  right: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row-reverse',
    alignItems: 'center',

    '& h1': {
      fontSize: '24px',
      lineHeight: '128%',
    },
  },

  'header-username': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.938rem',
  },

  icon: {
    color: 'white',
  },

  'menu-username': {
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    letterSpacing: '0.46px',
    color: '#5D73F5',
  },

  header__button: {
    width: '100%',
    height: '100%',
    minWidth: '30rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '2%',
  },

  header__buttonMargin: {
    marginRight: '3%',
  },

  'user-menu': {
    marginTop: '0.5rem',
    '& ul': {
      width: '9rem',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& li': {
      display: 'flex',
      width: '80%',
      paddingLeft: '0%',
      paddingRight: '0%',
      justifyContent: 'space-around',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '15px',
      color: '#5D73F5',
      lineHeight: '26px',
      letterSpacing: '0.46px',
    },
  },

  logo: {
    cursor: 'pointer',
    fontSize: 'xx-large',
    marginRight: '1.5rem',
    color: '#FFFFFF',
    minHeight: '40px',
    minWidth: '180px',
    width: '20%',
    height: '30%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  settings: {
    cursor: 'pointer',
    fontSize: 'xx-large',
    marginRight: '1.5rem',
    color: '#FFFFFF',
  },
});

export default useStyles;

import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import CreateSession from './pages/CreateSession/CreateSession';
import LogInPlayer from './pages/LoginPlayer/LogInPlayer';
import PlanningPokerSession from './pages/PlanningPokerSession/PlanningPokerSession';
import Home from './pages/Home/Home';
import Logout from './pages/Logout/Logout';
import Error from './pages/Error/Error';
import { SessionProvider } from './contexts/sessionContext';
import { client } from './services/graphql';
import { routes } from './constants/routes';
import { PlayerProvider } from './contexts/playerContext';
import theme from './Theme';
import Login from './pages/Login/Login';
import ClickUpAuth from './pages/ClickUpAuth/ClickUpAuth';
import SessionsHistory from './pages/SessionsHistory/SessionsHistory';
import HistoryDashboard from './pages/Dashboard/HistoryDashboard';
import { ModalsProvider } from './contexts/modalsContext';

const App = () => {
  return (
    <div className="page">
      <ApolloProvider client={client}>
        <PlayerProvider>
          <SessionProvider>
            <ThemeProvider theme={theme}>
              <ModalsProvider>
                <Switch>
                  <Route path="/" exact>
                    <Redirect to={routes.HOME} />
                  </Route>
                  <Route path={routes.HOME}>
                    <Home />
                  </Route>
                  <Route path={routes.CREATE_SESSION}>
                    <CreateSession />
                  </Route>
                  <Route path={routes.LOGIN_FORM}>
                    <LogInPlayer />
                  </Route>
                  <Route path={routes.DASHBOARD}>
                    <PlanningPokerSession />
                  </Route>
                  <Route path={routes.HISTORY_DASHBOARD}>
                    <HistoryDashboard />
                  </Route>
                  <Route path={routes.SESSIONS_HISTORY}>
                    <SessionsHistory />
                  </Route>
                  <Route path={routes.GAME_SETTINGS}>
                    <PlanningPokerSession />
                  </Route>
                  <Route path={routes.PLANNING_POKER_SESSION}>
                    <PlanningPokerSession />
                  </Route>
                  <Route path={routes.LOGIN}>
                    <Login />
                  </Route>
                  <Route path={routes.LOGOUT}>
                    <Logout />
                  </Route>
                  <Route exact path={routes.CLICKUP_AUTH}>
                    <ClickUpAuth />
                  </Route>
                  <Route exact path={routes.ERROR}>
                    <Error />
                  </Route>
                </Switch>
              </ModalsProvider>
            </ThemeProvider>
          </SessionProvider>
        </PlayerProvider>
      </ApolloProvider>
    </div>
  );
};
export default App;

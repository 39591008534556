import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from '../Issue/issueStyles';
import GeneralButton from '../../../../../components/GeneralButton/GeneralButton';
import { CREATE_ISSUE } from '../../../../../services/issue';
import { useSnackbarError } from '../../../../../hooks/useSnackbarError';
import { useSession } from '../../../../../contexts/sessionContext';
import { ISSUE_TITLE_REGEX } from '../../../../../constants/regex';

const LINEBREAK_REGEX = /[\r\n]/;
const ENDS_WITH_LINEBREAK_REGEX = /[\r\n]$/;

const MultipleIssues = ({ scrollRef, closeForm }) => {
  const issueClasses = useStyles();
  const {
    sessionState: { _id: sessionId },
  } = useSession();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const [tasksList, setTasksList] = useState([]);
  const [inputState, setInputState] = useState('');
  const [editIndex, setEditIndex] = useState(undefined);
  const [loadingIssues, setLoadingIssues] = useState(false);
  const [createIssue] = useMutation(CREATE_ISSUE, {
    onError: (error) => setErrorSnackbar(error?.message),
  });

  const isEditingATask = editIndex !== undefined;

  const saveTasks = (tasks) => {
    if (!tasks.length) return;

    if (!isEditingATask) {
      setTasksList((prevTaskList) => [...prevTaskList, ...tasks]);
      return;
    }

    setTasksList((prevTaskList) => {
      return prevTaskList.map((prevTask, i) => {
        return i === editIndex ? tasks[0] : prevTask;
      });
    });
    setEditIndex(undefined);
    setInputState('');
  };

  const onInputChange = ({ target: { value: input }, target }) => {
    const inputTasks = input.split(LINEBREAK_REGEX).filter((x) => x);
    if (!inputTasks.length) {
      setInputState('');
      return;
    }

    const endsWithLineBreak = ENDS_WITH_LINEBREAK_REGEX.test(input);
    endsWithLineBreak && isEditingATask && target.blur();

    const nextInputState = endsWithLineBreak ? '' : inputTasks.pop();
    setInputState(nextInputState);

    saveTasks(inputTasks);
  };

  const enableTaskEdition = (taskIndex) => setEditIndex(taskIndex);

  const disableTaskEdition = () => saveTasks([inputState]);

  const removeTask = (taskIndex) => {
    setTasksList((prevTaskList) => {
      return prevTaskList.filter((_, i) => taskIndex !== i);
    });
  };

  const addMultipleIssues = async () => {
    setLoadingIssues(true);
    if (tasksList.length) {
      tasksList.forEach(async (issue) => {
        const data = issue.replace(ISSUE_TITLE_REGEX, '');
        const variables = { sessionId, issueBody: { title: data } };
        await createIssue({ variables });
      });
    }
    inputState &&
      (await createIssue({
        variables: { sessionId, issueBody: { title: inputState } },
      }));
    setLoadingIssues(false);
    closeForm();
  };

  useEffect(() => {
    if (scrollRef) {
      scrollRef.current.scrollIntoView();
    }
  }, [tasksList?.length, inputState]);

  useEffect(() => {}, [loadingIssues]);

  return (
    <div className={issueClasses['issue-card']}>
      <div className={issueClasses['issue-card-head']}>
        <Typography
          variant="subtitle1"
          className={issueClasses['issue-card-title']}
        >
          Stories
        </Typography>
      </div>
      {tasksList.length ? (
        <List>
          {tasksList.map((value, index) => {
            return (
              <ListItem key={uuidv4()} role={undefined} dense button>
                {editIndex === index ? (
                  <TextField
                    id={`task-${index}-textfield`}
                    value={inputState}
                    fullWidth
                    autoFocus
                    multiline
                    onChange={onInputChange}
                    onFocus={() => setInputState(tasksList[editIndex])}
                    onBlur={disableTaskEdition}
                  />
                ) : (
                  <ListItemText
                    id={`task-${index}-label`}
                    primary={value}
                    onClick={() => enableTaskEdition(index)}
                    className={issueClasses['multiline-card-list-item-text']}
                  />
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="remove-task"
                    onClick={() => removeTask(index)}
                  >
                    <ClearIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      ) : (
        ''
      )}
      {!isEditingATask && (
        <TextField
          id="outlined-textarea"
          InputProps={{
            classes: {
              input: issueClasses['issue-text-field'],
            },
          }}
          value={inputState}
          multiline
          autoFocus
          placeholder="Write on a new line to add multiple stories"
          variant="outlined"
          onChange={onInputChange}
        />
      )}
      <div
        className={issueClasses['multiline-buttons-container']}
        ref={scrollRef || null}
      >
        <GeneralButton
          variant="outlined"
          onClick={addMultipleIssues}
          color="primary"
          label="Save"
          loading={loadingIssues}
        />
        <GeneralButton
          variant="contained"
          onClick={closeForm}
          color="primary"
          label="Cancel"
        />
      </div>
      {showErrorSnackbar()}
    </div>
  );
};

export default MultipleIssues;

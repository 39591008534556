import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingBottom: "12%",
    zIndex: "3",
  },

  title: {
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "300",
    "font-size": "96px",
    "line-height": "112px",
    " letter-spacing": "-1.5px",
    marginBottom: "2%",
    zIndex: "3",
  },

  paragrahp: {
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "20px",
    "line-height": "160%",
    " letter-spacing": "0.15px",
    "text-align": "center",
    color: "#FFFFFF",
    "max-width": "550px",
    marginBottom: "5%",
    zIndex: "3",
  },

  rocket: {
    position: "absolute",
    bottom: "20%",
    right: "10%",
    transform: "translatey(0px)",
    animation: "$float 6s ease-in-out infinite",
    zIndex: "2",
  },

  "@keyframes float": {
    "0%": {
      transform: "translatey(0px) ",
    },
    "50%": {
      transform: "translatey(-40px) ",
    },
    "100%": {
      transform: "translatey(0px) ",
    },
  },

  "@media screen and (max-width: 480px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 480px) and (max-width: 768px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 768px) and (max-width: 992px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 992px) and (max-width: 1200px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 1200px) and (max-width: 1440p)": {
    foo: "bar",
  },
  "@media screen and (min-width: 1440p)": {
    foo: "bar",
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  player: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "auto",
  },

  tooltip: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "11px",
    backgroundColor: "#E961FF",
  },

  arrow: {
    color: "#E961FF",
  },

  "flip-card": {
    width: "100%",
    height: "100%",
    maxWidth: "4rem",
    "max-height": "5rem",
    minHeight: "0.5vw",
    minWidth: "0.5vw",

    "transform-style": "preserve-3d",
    transition: "transform 1s",
    position: "relative",

    "&.click": {
      transform: "rotateY(180deg)",
    },
  },

  front: {
    position: "absolute",
    "backface-visibility": "hidden",
    width: "100%",
    height: "100%",
  },

  back: {
    "backface-visibility": "hidden",
    position: "absolute",
    transform: "rotateY(180deg)",
    width: "100%",
    height: "100%",
  },

  "@media screen and (max-width: 480px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 480px) and (max-width: 768px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 768px) and (max-width: 992px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 992px) and (max-width: 1200px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 1200px) and (max-width: 1440p)": {
    foo: "bar",
  },
  "@media screen and (min-width: 1440p)": {
    foo: "bar",
  },
});

export default useStyles;

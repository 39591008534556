import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './cardItemStyles';

const CardItem = ({ number, variant }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      className={`${classes.card} ${variant && classes[variant]} central`}
    >
      <h5 className={classes['number-color']}>{number}</h5>
    </Grid>
  );
};

export default CardItem;

export const countCardVotes = (votes) => {
  const count = votes.reduce((count, vote) => {
    count[vote] = (count[vote] || 0) + 1
    return count
  }, {})
  return count;
};

export const calculateAverage = (votes) => {
  const sum = votes.reduce(
    (total, value) => total + (Number.isInteger(value) ? value : 0)
  );
  const result = Math.trunc(sum / votes.length);
  return result.toString();
};

export const calculateMajority = (votes) => {
  const votesCounts = countCardVotes(votes);
  const result = Object.keys(votesCounts).reduce((prev, current) =>
    votesCounts[prev] > votesCounts[current] ? prev : current
  );
  return result;
};

export const calculateAgreementValue = (votes) => {
  const votesCounts = countCardVotes(votes);
  const counts = Object.values(votesCounts);
  const max = Math.max(...counts);
  const agreement = max === 1 ? 0 : Math.trunc((max / votes.length) * 100);
  return agreement;
};

export const calculateAgreementLevel = (agreement) => {
  return agreement >= 70 ? 'high' : agreement >= 30 ? 'mid' : 'low';
}

export const calculateResult = (strategy, votes) => {
  switch (strategy) {
    case "Average":
      return calculateAverage(votes);
    case "Majority":
      return calculateMajority(votes);
    default:
      return "Invalid strategy";
  }
};
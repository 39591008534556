import TextField from "@material-ui/core/TextField";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./textInputStyles";


const TextInput = ({
  name,
  placeholder,
  value,
  setValue,
  stateName,
  isDisabled = false,
  canCopy = false,
}) => {
  const classes = useStyles();

  const onChangeHandler = (e) => {
    setValue((prevState) => ({ ...prevState, [stateName]: e.target.value }));
  };

  const onCopyLinkHandler = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      margin="normal"
      label={name}
      onChange={onChangeHandler}
      name={name}
      placeholder={placeholder}
      value={value}
      disabled={isDisabled}
      className={classes["text-field"]}
      InputProps={
        canCopy ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="Copy link" onClick={onCopyLinkHandler}>
                <LinkOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        } : {}
      }
    />
  );
};

export default TextInput;

import { isLocalhost } from "./isLocalHost";
import awsconfig from "../aws-exports";

const [developmentRedirectSignIn, productionRedirectSignIn] =
  awsconfig.oauth.redirectSignIn.split(",");
const [developmentRedirectSignOut, productionRedirectSignOut] =
  awsconfig.oauth.redirectSignOut.split(",");

export const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isLocalhost
      ? developmentRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? developmentRedirectSignOut
      : productionRedirectSignOut,
  },
};

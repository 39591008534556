import useStyles from "./cloudStyles";
import cloud from "../../../assets/background/cloud.svg";

const Cloud = ({
  height,
  width,
  positionY,
  positionX,
  animationDuration = "6s",
}) => {
  const props = { height, width, positionY, positionX, animationDuration };
  const classes = useStyles(props);
  return <img className={`${classes.cloud}`} src={cloud} alt="cloud"></img>;
};

export default Cloud;

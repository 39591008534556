/* eslint-disable import/no-named-default */
import React, { useState, useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Issue from '../Issue/Issue';
import { useSession } from '../../../../../contexts/sessionContext';
import { usePlayerContext } from '../../../../../hooks/usePlayerContext';
import { default as issuesListStyles } from './issueListStyles';
import { default as issuesStyles } from '../issuePanelStyles';
import { useModals } from '../../../../../contexts/modalsContext';
import { DELETE_ISSUE } from '../../../../../services/issue';
import IssueDialog from '../IssueDialog/IssueDialog';
import { useSnackbarError } from '../../../../../hooks/useSnackbarError';
import MultipleIssues from '../MultipleIssues/MultipleIssues';
import GeneralButton from '../../../../../components/GeneralButton/GeneralButton';

const IssuesList = () => {
  const issuesListClasses = issuesListStyles();
  const issuesClasses = issuesStyles();
  const {
    sessionState: { _id: sessionId, currentIssue, issues },
  } = useSession();
  const [issueIdToModify, setIssueIdToModify] = useState('');
  const [issueIdToDelete, setIssueIdToDelete] = useState('');
  const [currentView, setCurrentView] = useState('');
  const { modalsState, toggleDeleteIssueDialog } = useModals();
  const scrollRef = useRef(null);
  const {
    player: {
      permissions: { manageIssues },
    },
  } = usePlayerContext();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();

  const [deleteIssue] = useMutation(DELETE_ISSUE, {
    variables: { sessionId, issueId: issueIdToDelete },
    onError: (error) => setErrorSnackbar(error?.message),
  });

  const issueStateHandler = (issueId) => {
    if (issueIdToModify === '') {
      setIssueIdToModify(issueId);
    } else if (issueId === '') {
      setIssueIdToModify('');
    }
  };

  const deleteHandler = (event, issueId) => {
    if (manageIssues && currentIssue !== issueId) {
      toggleDeleteIssueDialog(event);
      setIssueIdToDelete(issueId || '');
    }
  };

  const deleteIssueHandler = async (event) => {
    if (manageIssues && currentIssue !== issueIdToDelete) {
      await deleteIssue();
      setIssueIdToDelete('');
      toggleDeleteIssueDialog(event);
    }
  };

  const updateCurrentView = useCallback((aCurrentView = '') => {
    setCurrentView(aCurrentView);
  }, []);

  const newIssue = () => (
    <Issue
      issueId="newIssue"
      title=""
      number={issues.length + 1}
      issueEditing={issueIdToModify}
      issueStateHandler={issueStateHandler}
      onSaveOrCancel={updateCurrentView}
      scrollRef={scrollRef}
    />
  );

  const handleAddNewIssueClick = () => {
    updateCurrentView('newIssue');
    issueStateHandler('newIssue');
  };

  const addIssuesButtons = () => {
    return (
      <Box className={issuesClasses['buttons-container']}>
        <GeneralButton
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => handleAddNewIssueClick()}
          startIcon={<AddIcon />}
          label="Story"
        />
        <GeneralButton
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => updateCurrentView('multiple-issues')}
          startIcon={<LibraryAddIcon />}
          label="Stories"
        />
      </Box>
    );
  };

  currentView === 'newIssue' && scrollRef?.current?.scrollIntoView();

  return (
    <>
      {issues?.map((issue, index) => (
        <Issue
          key={issue._id}
          issueId={issue._id}
          issueEditing={issueIdToModify}
          title={issue.title}
          number={index + 1}
          result={issue.result}
          clickupTaskId={issue.clickupTaskId}
          issueStateHandler={issueStateHandler}
          deleteHandler={deleteHandler}
          scrollRef={scrollRef}
        />
      ))}
      <IssueDialog
        state={modalsState.deleteIssueDialog}
        title="Are you sure you want to delete this task?"
        toggle={deleteHandler}
        handler={deleteIssueHandler}
      />
      {manageIssues && (
        <>
          {currentView === '' && issueIdToModify === '' && addIssuesButtons()}
          {currentView === 'newIssue' && newIssue()}
          {currentView === 'multiple-issues' && (
            <MultipleIssues
              scrollRef={scrollRef}
              closeForm={() => updateCurrentView()}
            />
          )}
        </>
      )}
      {!manageIssues && issues?.length === 0 && (
        <Box className={issuesListClasses['panel-message']}>
          <Box sx={{ fontSize: '70px', marginBottom: '2rem' }}>⌛</Box>
          Wait until the host adds stories
        </Box>
      )}
      {showErrorSnackbar()}
    </>
  );
};

export default IssuesList;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import GeneralCardsPanel from '../../../../components/GeneralCardsPanel/GeneralCardsPanel';
import { scales } from '../../../../constants/scales';
import { useSession } from '../../../../contexts/sessionContext';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import { UPDATE_ISSUE } from '../../../../services/issue';
import useStyles from './planningPokerBodyStyles';

const QuickResult = ({ onClose }) => {
  const classes = useStyles();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const onError = (error) => setErrorSnackbar(error?.message);
  const {
    sessionState: { _id: sessionId, currentIssue, scale: scaleName },
  } = useSession();
  const [updateIssue] = useMutation(UPDATE_ISSUE, { onError });
  const [quickResult, setQuickResult] = useState('');

  const scale = scales[scaleName];

  const sendQuickResult = async (card) => {
    if (card) {
      setQuickResult(card);
      onClose();
      await updateIssue({
        variables: {
          sessionId,
          issueId: currentIssue,
          issueBody: { result: card },
        },
      });
    }
  };

  const handleClickAway = () => onClose();

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes['quick-result-container']}>
          <div className={classes['quick-result-cards-panel']}>
            <GeneralCardsPanel
              scale={scale}
              currentIssue={currentIssue}
              cardSelected={quickResult}
              setCardSelected={sendQuickResult}
              variant="issue"
            />
          </div>
        </div>
      </ClickAwayListener>
      {showErrorSnackbar()}
    </>
  );
};

export default QuickResult;

import React from 'react';
import { DialogTitle, Dialog, Box } from '@material-ui/core';
import GeneralButton from '../../../../../components/GeneralButton/GeneralButton';
import useStyles from './issueDialogStyles';

const IssueDialog = ({ state, title, toggle, handler, children }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={state} onClose={toggle}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      {children && <Box marginY={1}>{children}</Box>}
      <Box display="flex" justifyContent="flex-end" mt={1}>
        <GeneralButton
          variant="text"
          color="secondary"
          label="NO"
          onClick={toggle}
          size="large"
        />
        <GeneralButton
          variant="contained"
          color="secondary"
          label="YES"
          onClick={handler}
          size="large"
        />
      </Box>
    </Dialog>
  );
};

export default IssueDialog;

import { gql } from "@apollo/client";

export const CREATE_VOTE = gql`
  mutation createVote(
    $sessionId: String!
    $issueId: String!
    $voteBody: CreateVoteInput!
  ) {
    createVote(sessionId: $sessionId, issueId: $issueId, voteBody: $voteBody) {
      _id
    }
  }
`;

export const UPDATE_VOTE = gql`
  mutation updateVote(
    $sessionId: String!
    $issueId: String!
    $voteId: String!
    $voteBody: UpdateVoteInput!
  ) {
    updateVote(
      sessionId: $sessionId
      issueId: $issueId
      voteId: $voteId
      voteBody: $voteBody
    ) {
      _id
    }
  }
`;

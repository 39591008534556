import React from 'react';
import { useSession } from '../../contexts/sessionContext';
import DashboardContent from './DashboardContent';

const Dashboard = () => {
  const {
    sessionState: { scale, players, issues },
  } = useSession();

  return <DashboardContent scale={scale} players={players} issues={issues} />;
};

export default Dashboard;

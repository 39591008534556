import LeftItems from './LeftItems/LeftItems';
import RightItems from './RightItems/RightItems';
import useStyles from './headerStyles';

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <LeftItems />
      <RightItems />
    </div>
  );
};

export default Header;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'results-title': {
    display: 'flex',
    color: '#c8cdff',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '0px',
  },

  'consensus-title': {
    backgroundColor: '#E961FF',
    borderRadius: '6px',
    padding: '0.3rem 1rem',
  },

  'background-circle': {
    position: 'absolute',
    zIndex: '1',
    right: '0',
    '& svg': {
      color: '#fffcfc3d',
    },
  },

  span: {
    paddingRight: '0.5rem',
  },

  'circle-high': {
    '& svg': {
      color: '#2cc05b',
    },
  },

  'circle-mid': {
    '& svg': {
      color: '#e1ed3d',
    },
  },

  'circle-low': {
    '& svg': {
      color: '#c02c2c',
    },
  },
});

export default useStyles;

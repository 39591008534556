import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '4vh',
    },
  },
  title: {
    padding: '0',
    marginBottom: theme.spacing(1),
    '& h2': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '32px',
      color: ' #000000',
    },
  },
}));

export default useStyles;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from '../PlanningPokerBody/planningPokerBodyStyles';

const SessionName = ({ sessionName }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="subtitle1"
      align="center"
      className={classes['session-name']}
    >
      Session: {sessionName}
    </Typography>
  );
};

export default SessionName;

import { split, HttpLink, ApolloClient, InMemoryCache } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { ApolloLink, Observable } from "@apollo/client/core";
import { print } from "graphql";
import { createClient } from "graphql-ws";

const REACT_APP_GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;
const REACT_APP_SUBSCRIPTIONS_URL = process.env.REACT_APP_SUBSCRIPTIONS_URL;

class WebSocketLink extends ApolloLink {
  client;
  clearSubscription;

  constructor(options) {
    super();
    this.client = createClient(options);
  }

  request(operation) {
    return new Observable((sink) => {
      this.clearSubscription = this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: () => { },
        }
      );
      return this.clearSubscription;
    });
  }
}

let timedOut;
const link = new WebSocketLink({
  url: REACT_APP_SUBSCRIPTIONS_URL,
  lazy: true,
  disablePong: false,
  keepAlive: 10000,
  on: {
    pong: (received) => received && clearTimeout(timedOut),
  },
});
const httpLink = new HttpLink({
  uri: REACT_APP_GRAPHQL_URL,
  credentials: "include",
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  link,
  httpLink
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({ addTypename: false }),
});

export const unsubscribe = () => {
  if (link?.clearSubscription) {
    link.clearSubscription();
    link.clearSubscription = undefined;
    clearTimeout(timedOut);
  }
};

export const onSubscribed = ({ subscribed, closed }) => {
  link.client.on("ping", (received, payload) => {
    if (received) {
      if (payload?.message === "subscribed") subscribed();
    } else {
      timedOut = setTimeout(closed, 5000);
    }
  });
};

import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  TextField,
  IconButton,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { useSession } from '../../../../../contexts/sessionContext';
import { UPDATE_ISSUE, CREATE_ISSUE } from '../../../../../services/issue';
import IssueButtonContainer from '../IssueButtonContainer/IssueButtonContainer';
import { UPDATE_SESSION } from '../../../../../services/session';
import { usePlayerContext } from '../../../../../hooks/usePlayerContext';
import { validateIssue } from '../../../../../helpers/Validate';
import GeneralCardsPanel from '../../../../../components/GeneralCardsPanel/GeneralCardsPanel';
import { scales } from '../../../../../constants/scales';
import { useSnackbarError } from '../../../../../hooks/useSnackbarError';
import clickup from '../../../../../assets/issuePanel/clickup.svg';
import { useModals } from '../../../../../contexts/modalsContext';
import useStyles from './issueStyles';
import { useTimerContext } from '../../../../../contexts/timerContext';

const Issue = ({
  issueId,
  issueEditing,
  title,
  result,
  number,
  clickupTaskId,
  issueStateHandler,
  deleteHandler,
  onSaveOrCancel,
  scrollRef,
}) => {
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const { toggleDrawer } = useModals();
  const [createIssue, { loading: loadingCreateIssue }] = useMutation(
    CREATE_ISSUE,
    {
      onError: (error) => setErrorSnackbar(error?.message),
    }
  );
  const [updateIssue, { loading: loadingIssue }] = useMutation(UPDATE_ISSUE, {
    onError: (error) => setErrorSnackbar(error?.message),
  });
  const [updateSession, { loading: loadingSession }] = useMutation(
    UPDATE_SESSION,
    {
      onError: (error) => setErrorSnackbar(error?.message),
    }
  );
  const [cardSelected, setCardSelected] = useState('');
  const {
    sessionState: { _id: sessionId, currentIssue, timer, scale: scaleName },
  } = useSession();
  const {
    player: {
      permissions: { manageIssues },
    },
  } = usePlayerContext();
  const {
    timerState: { input: timerInput },
    dispatch,
  } = useTimerContext();
  const [inputState, setInputState] = useState(title);
  const [openCardsPanel, setOpenCardsPanel] = useState(false);
  const classes = useStyles(issueEditing === issueId);
  const scale = scales[scaleName];
  const showActionButtons = manageIssues && issueEditing !== issueId;
  const isDisabledEditButton =
    currentIssue === issueId || clickupTaskId || issueEditing !== '';
  const clickupURL = `${process.env.REACT_APP_CLICKUP_BASE_URL}${clickupTaskId}`;

  useEffect(() => {
    setOpenCardsPanel(false);
  }, [currentIssue]);

  const selectIssue = async () => {
    await updateSession({
      variables: {
        sessionId,
        sessionBody: { revealed: false, currentIssue: issueId },
      },
    });
    toggleDrawer();
    timer?.switch &&
      dispatch({
        time: timerInput,
        state: 'active',
        date: Date.now(),
        switch: timer?.switch,
      });
    await updateIssue({
      variables: {
        sessionId,
        issueId,
        deleteVotes: true,
      },
    });
  };

  const addIssue = async () => {
    if (!inputState || !validateIssue(inputState)) {
      const message = !inputState
        ? 'Input title required'
        : 'Input title invalid';
      setErrorSnackbar(message);
      return;
    }
    const updatedInput = inputState.trim();
    setInputState(updatedInput);
    if (title === '') {
      await createIssue({
        variables: {
          sessionId,
          issueBody: { title: updatedInput },
        },
      });
      setInputState('');
      issueStateHandler('newIssue');
    } else {
      await updateIssue({
        variables: {
          sessionId,
          issueId,
          issueBody: { title: updatedInput },
        },
      });
      issueStateHandler('');
      onSaveOrCancel();
    }
  };

  const sendResult = useCallback(
    async (card) => {
      if (card) {
        setCardSelected(card);
        await updateIssue({
          variables: {
            sessionId,
            issueId,
            issueBody: { result: card },
          },
        });
        currentIssue === issueId &&
          (await updateSession({
            variables: { sessionId, sessionBody: { currentIssue: '' } },
          }));
        setOpenCardsPanel(false);
      }
    },
    [
      currentIssue,
      updateSession,
      updateIssue,
      setOpenCardsPanel,
      sessionId,
      issueId,
    ]
  );

  const editHandler = () => {
    manageIssues &&
      currentIssue !== issueId &&
      !clickupTaskId &&
      issueEditing === '' &&
      issueStateHandler(issueId);
  };

  const cancelIssueEdit = () => {
    issueStateHandler('');
    setInputState(title);
    onSaveOrCancel();
  };

  const handleEnterKey = (event) => event.key === 'Enter' && addIssue();

  const cardTitle = () => {
    return (
      <Typography variant="subtitle1" className={classes['issue-card-title']}>
        Story {number}
      </Typography>
    );
  };

  const clickupCardTitle = () => {
    return (
      <>
        <Tooltip placement="top-start" title="Open task in clickup">
          <Link
            href={clickupURL}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            {cardTitle()}
          </Link>
        </Tooltip>
        <img
          src={clickup}
          alt="Clickup logo"
          className={classes['issue-card-clickup-logo']}
        />
      </>
    );
  };

  useEffect(() => {
    if (currentIssue === issueId || issueId === 'newIssue') {
      scrollRef?.current?.scrollIntoView();
    }
  }, [currentIssue, inputState]);

  return (
    <>
      <div
        className={classes['issue-card']}
        id={`issue-${issueId}`}
        ref={
          currentIssue === issueId || issueId === 'newIssue' ? scrollRef : null
        }
      >
        <div className={classes['issue-card-head']}>
          <div className={classes['issue-card-left-items']}>
            {clickupTaskId ? clickupCardTitle() : cardTitle()}
          </div>

          {showActionButtons && (
            <div className={classes['issue-card-right-items']}>
              <IconButton
                className={classes['issue-card-icon-button']}
                onClick={(event) => deleteHandler(event, issueId)}
                disabled={currentIssue === issueId}
                size="small"
              >
                <DeleteOutlineIcon />
              </IconButton>
              <IconButton
                className={classes['issue-card-icon-button']}
                onClick={editHandler}
                disabled={isDisabledEditButton}
                size="small"
              >
                <EditIcon />
              </IconButton>
            </div>
          )}
        </div>
        <TextField
          id="outlined-textarea"
          InputProps={{
            classes: {
              input: classes['issue-text-field'],
            },
          }}
          value={inputState}
          disabled={!(issueEditing === issueId)}
          autoFocus
          placeholder="Write your story"
          variant="outlined"
          onChange={(event) => setInputState(event.target.value)}
          onKeyPress={handleEnterKey}
          multiline
        />
        <IssueButtonContainer
          selectIssue={selectIssue}
          issueId={issueId}
          editingState={issueEditing === issueId}
          createIssue={addIssue}
          setOpenCardsPanel={setOpenCardsPanel}
          openCardsPanel={openCardsPanel}
          result={result}
          options={scale}
          cancelIssueEdit={cancelIssueEdit}
          loading={loadingIssue || loadingCreateIssue || loadingSession}
        />
      </div>

      {openCardsPanel && (
        <GeneralCardsPanel
          scale={scale}
          currentIssue={currentIssue}
          cardSelected={cardSelected}
          setCardSelected={sendResult}
          variant="issue"
        />
      )}
      {showErrorSnackbar()}
    </>
  );
};

export default Issue;

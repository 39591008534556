import React from 'react';
import { Grid, Typography } from '@mui/material';
import planet from '../../assets/dashboard/planet.svg';
import useStyles from './dashboardStyles';

const DashboardContent = ({ scale, issues, players }) => {
  const classes = useStyles();

  const calculateTotal = () => {
    const results = issues
      ?.filter((issue) => issue.result !== '?' && issue.result !== '-')
      .map((issue) => parseInt(issue.result, 10));
    const sum = results?.reduce((total, value) => total + value, 0);
    return results?.length > 0 ? sum : '?';
  };

  return (
    <div className={classes.body}>
      <Grid item className={classes.container}>
        <Grid item className={classes['big-panel']} direction="column">
          <Typography variant="h5" align="center">
            Participants
          </Typography>
          <Typography variant="h1" align="center">
            {players?.length}
          </Typography>
          <Grid className={classes['players-container']}>
            {players?.map((player) => (
              <div
                key={`${player.name}-container`}
                className={classes['player-name-container']}
              >
                <h2>{player.name}</h2>
              </div>
            ))}
          </Grid>
        </Grid>
        <Grid item className={classes['big-panel']} direction="column">
          <Typography variant="h5" align="center">
            Story Points
          </Typography>
          <Grid className={classes['issues-container']}>
            {issues?.map((issue, index) => (
              <div
                key={`${issue._id}-container`}
                className={classes['issue-container']}
              >
                <div className={classes['issue-title']}>
                  <h2>
                    Story {index + 1}: {issue.title}
                  </h2>
                </div>
                <div className={classes['issue-result']}>
                  <h2>{issue.result}</h2>
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          className={classes['little-panels-container']}
          direction="column"
        >
          <Grid item className={classes['little-panel']} direction="column">
            <Typography variant="h5" align="center">
              Total
            </Typography>
            {scale === 'T-shirts' ? (
              <Typography variant="h6" align="center">
                Not available for T-shirts scale
              </Typography>
            ) : (
              <Typography variant="h1" align="center">
                {calculateTotal()}
              </Typography>
            )}
          </Grid>
          <Grid item className={classes.planet}>
            <img src={planet} alt="planet" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardContent;

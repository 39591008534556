const isAplhanumeric = (input) => /[A-Za-zÀ-ȕ0-9]/i.test(input);
const isValidName = (input) => /^[A-Za-zÀ-ȕ0-9(),-_.'/ ]+$/i.test(input);
const isValidTitle = (input) => /^[A-Za-zÀ-ȕ0-9(),-_.'"\n/ ]+$/i.test(input);

export const validateName = (input) => {
  const trimmed = input.trim();
  return (
    input.length < 21 &&
    trimmed &&
    isAplhanumeric(trimmed) &&
    isValidName(trimmed)
  );
};

export const validateIssue = (input) => {
  const trimmed = input.trim();
  return trimmed && isAplhanumeric(trimmed) && isValidTitle(trimmed);
};

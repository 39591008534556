import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  telescopeImg: {
    overflow: "hidden",
    height: "100%",
  },
  text: {
    position: "static",
    width: "743px",
    left: "calc(50% - 743px/2)",
    fontFamily: " Open Sans",
    fontWeight: "300",
    fontSize: "60px",
    lineHeight: "120%",
    letterSpacing: "-0.5px",
    color: "#FFFFFF",
  },

  "@media screen and (max-width: 480px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 480px) and (max-width: 768px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 768px) and (max-width: 992px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 992px) and (max-width: 1200px)": {
    foo: "bar",
  },
  "@media screen and (min-width: 1200px) and (max-width: 1440p)": {
    foo: "bar",
  },
  "@media screen and (min-width: 1440p)": {
    foo: "bar",
  },
});

export default useStyles;

import React from 'react';
import { useMutation } from '@apollo/client';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './issueButtonContainerStyles';
import { usePlayerContext } from '../../../../../hooks/usePlayerContext';
import { useSession } from '../../../../../contexts/sessionContext';
import GeneralButton from '../../../../../components/GeneralButton/GeneralButton';
import { useModals } from '../../../../../contexts/modalsContext';
import IssueDialog from '../IssueDialog/IssueDialog';
import { useSnackbarError } from '../../../../../hooks/useSnackbarError';
import { UPDATE_SESSION } from '../../../../../services/session';
import { UPDATE_ISSUE } from '../../../../../services/issue';

const IssueButtonContainer = ({
  selectIssue,
  issueId,
  editingState,
  createIssue,
  setOpenCardsPanel,
  openCardsPanel,
  result,
  cancelIssueEdit,
  loading = false,
}) => {
  const classes = useStyles();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const { modalsState, toggleCancelVoteDialog } = useModals();
  const {
    player: {
      permissions: { manageIssues },
    },
  } = usePlayerContext();
  const {
    sessionState: { _id: sessionId, currentIssue },
  } = useSession();
  const [updateSession] = useMutation(UPDATE_SESSION, {
    variables: { sessionId, sessionBody: { currentIssue: '' } },
    onError: (error) => setErrorSnackbar(error?.message),
  });
  const [updateIssue] = useMutation(UPDATE_ISSUE, {
    variables: { sessionId, issueId, deleteVotes: true },
    onError: (error) => setErrorSnackbar(error?.message),
  });
  const showActionButton =
    (!editingState || result !== '-') && !editingState && result;

  const cancelVote = async (e) => {
    await updateSession();
    await updateIssue();
    toggleCancelVoteDialog(e);
  };

  return (
    <div className={classes['issue-buttons-container']}>
      <div className={classes['issue-left-buttons']}>
        {currentIssue === issueId ? (
          <>
            <GeneralButton
              variant="contained"
              disabled={!manageIssues}
              onClick={toggleCancelVoteDialog}
              color="primary"
              label="cancel voting"
            />
            <IssueDialog
              state={modalsState.cancelVoteDialog}
              title="This issue is being voted. Do you want to cancel the voting session?"
              toggle={toggleCancelVoteDialog}
              handler={cancelVote}
            />
          </>
        ) : (
          !editingState && (
            <GeneralButton
              variant="contained"
              disabled={!manageIssues}
              onClick={selectIssue}
              color="primary"
              label={result !== '-' ? 'Revote' : 'Vote'}
              loading={loading}
            />
          )
        )}
      </div>
      <div className={classes['issue-right-buttons']}>
        {showActionButton && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Edit storypoint"
            arrow
            placement="bottom-end"
          >
            <IconButton
              className={classes['issue-average-button']}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => setOpenCardsPanel(!openCardsPanel)}
              disabled={!manageIssues}
            >
              <p className={classes['issue-average-button-p']}>{result}</p>
            </IconButton>
          </Tooltip>
        )}
        {editingState && (
          <>
            <GeneralButton
              variant="outlined"
              disabled={!manageIssues}
              onClick={createIssue}
              color="primary"
              label="Save"
              loading={loading}
            />

            <GeneralButton
              variant="contained"
              disabled={!manageIssues}
              onClick={cancelIssueEdit}
              color="primary"
              label="Cancel"
            />
          </>
        )}
      </div>
      {showErrorSnackbar()}
    </div>
  );
};

export default IssueButtonContainer;

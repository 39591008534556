import ignoredErrors from '../constants/ignoredErrors';

const logError = console.error;

const ignoreErrorLogs = (...params) => {
  const ignoreError = ignoredErrors.find((error) => {
    const errorFound = error.every((emsg) => {
      const errorStringFound = !!params.find((param) => param.includes(emsg));
      return errorStringFound;
    });
    return errorFound;
  });

  !ignoreError && logError(...params);
};

export default ignoreErrorLogs;

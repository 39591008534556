import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import logoImg from '../../../assets/header/nanLogo.svg';
import { useSession } from '../../../contexts/sessionContext';
import { routes } from '../../../constants/routes';
import useStyles from '../headerStyles';
import Timer from './Timer/Timer';

const mapPagetoIcons = () => ({
  [routes.PLANNING_POKER_SESSION]: () => <Timer />,
});

const LeftItems = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [leftItems, setLeftItems] = useState(mapPagetoIcons()[path]);
  const {
    sessionState: { _id: sessionId },
  } = useSession();

  const onClick = () => {
    history.push(routes.HOME);
  };

  useEffect(() => {
    sessionId && setLeftItems(mapPagetoIcons()[path]);
  }, [path, sessionId]);

  return (
    <div className={classes.left}>
      <Button onClick={onClick} className={classes.logo}>
        <img src={logoImg} alt="logo" />
      </Button>
      {leftItems}
    </div>
  );
};

export default LeftItems;

import React from 'react';
import Header from '../../components/Header/Header';
import useStyles from './errorStyle';
import telescope from '../../assets/error/telescope.svg';
import Background from '../../components/Background/Background';

const Error = () => {
  const classes = useStyles();

  return (
    <Background>
      <Header />
      <div className={classes.mainContainer}>
        <img className={classes.telescopeImg} src={telescope} alt="telescope" />
        <div className={`${classes.text}`}>
          <p>Oops, there's no result yet. Come back later</p>
        </div>
      </div>
    </Background>
  );
};

export default Error;

import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import Papa from 'papaparse';
import { useMutation } from '@apollo/client';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PublishIcon from '@material-ui/icons/Publish';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { CREATE_ISSUE } from '../../../../../services/issue';
import { useSnackbarError } from '../../../../../hooks/useSnackbarError';
import { useSession } from '../../../../../contexts/sessionContext';
import useStyles from './CSVIssuesStyles';
import { ISSUE_TITLE_REGEX } from '../../../../../constants/regex';
import GeneralButton from '../../../../../components/GeneralButton/GeneralButton';

const acceptedExtensions =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

const CSVIssues = ({ handleClose }) => {
  const classes = useStyles();
  const {
    sessionState: { _id: sessionId },
  } = useSession();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const [createIssue] = useMutation(CREATE_ISSUE, {
    onError: (error) => setErrorSnackbar(error?.message),
  });
  const fileInput = useRef();
  const [fileState, setFileState] = useState(null);
  const [parsedCsvData, setParsedCsvData] = useState(null);
  const [checked, setChecked] = useState(true);

  const parseFile = (file) => {
    Papa.parse(file, {
      header: checked,
      skipEmptyLines: true,
      complete: ({ data }) => {
        const issues = data.map((issue) => {
          const issueIndex = checked ? Object.keys(issue)[0] : 0;
          return issue[issueIndex].replace(ISSUE_TITLE_REGEX, '');
        });
        setParsedCsvData(issues);
      },
    });
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      setFileState(e.target.files[0]);
    }
  };

  const addMultipleIssues = (issues) => {
    issues.map(async (issue) => {
      await createIssue({
        variables: {
          sessionId,
          issueBody: { title: issue },
        },
      });
    });
    handleClose();
  };

  const onFileUpload = () => parseFile(fileState);

  const onCancel = () => {
    setFileState(null);
    setParsedCsvData(null);
    handleClose();
  };

  const onCheckBox = (e) => setChecked(e.target.checked);

  useEffect(() => {
    if (parsedCsvData) {
      addMultipleIssues(parsedCsvData);
    }
  }, [parsedCsvData]);

  const buttonLabel = fileState ? 'Choose Another File' : 'Choose File';

  return (
    <>
      <Box display="flex" justifyContent="space-between" p={2}>
        <GeneralButton
          onClick={onCancel}
          startIcon={<ClearIcon />}
          color="primary"
          label="Cancel"
          variant="contained"
          size="large"
        />
        <GeneralButton
          color="primary"
          onClick={() => fileInput.current.click()}
          startIcon={<AttachFileIcon />}
          label={buttonLabel}
          variant="contained"
          size="large"
        />
      </Box>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onCheckBox} />}
        label="Use first csv row as headers"
        className={classes['csv-checkbox-label']}
      />

      <input
        accept={acceptedExtensions}
        onChange={onFileChange}
        type="file"
        ref={fileInput}
        style={{ display: 'none' }}
      />
      {fileState && (
        <>
          <div className={classes['csv-file-selected']}>
            <CheckIcon />
            <p className={classes['csv-file-selected-p']}>
              {fileState.name} selected
            </p>
          </div>
          <Box display="flex" p={2}>
            <GeneralButton
              onClick={onFileUpload}
              startIcon={<PublishIcon />}
              color="primary"
              label="Upload File!"
              variant="contained"
              size="large"
            />
          </Box>
        </>
      )}
      {showErrorSnackbar()}
    </>
  );
};

export default CSVIssues;

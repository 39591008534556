import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import useStyles from './generalButtonStyles';

const GeneralButton = ({
  variant,
  color,
  size,
  label,
  onClick,
  startIcon,
  endIcon,
  disabled = false,
  width,
  height,
  loading = false,
}) => {
  const props = { width, height };
  const classes = useStyles(props);
  return (
    <Button
      {...{ color, variant, size, onClick, startIcon, endIcon, disabled }}
      classes={{ root: classes.button }}
    >
      {loading ? <CircularProgress size="1.50rem" thickness={5} /> : label}
    </Button>
  );
};

export default GeneralButton;

import React, { useEffect, useState } from 'react';
import { usePlayerContext } from '../../../../../hooks/usePlayerContext';
import useStyles from '../IssuesList/issueListStyles';

import ImportFromClickupForm from './ImportFromClickupForm';

import { routes } from '../../../../../constants/routes';
import { useSession } from '../../../../../contexts/sessionContext';
import { useSnackbarError } from '../../../../../hooks/useSnackbarError';

const clientID = process.env.REACT_APP_CLICKUP_CLIENT_ID;

const ImportFromClickup = ({ handleClose }) => {
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const {
    player: {
      permissions: { manageIssues },
      isLoggedInClickup,
    },
    setPlayer,
  } = usePlayerContext();
  const {
    sessionState: { clickupResultFieldId },
  } = useSession();
  const [importStatus, setImportStatus] = useState('');

  const handleCloseForm = () => {
    setImportStatus('');
    handleClose();
  };

  const classes = useStyles();
  const importFromClickupHandler = () => {
    if (!isLoggedInClickup) {
      const url = `${window.location.origin.toString()}${routes.CLICKUP_AUTH}`;
      window.open(
        `https://app.clickup.com/api?client_id=${clientID}&redirect_uri=${url}`,
        'oauthClickUp',
        'popup, width=700, height=700'
      );
    }
    handleClose();
  };

  useEffect(() => {
    if (clickupResultFieldId && importStatus === '') {
      setImportStatus('error');
      setErrorSnackbar(
        'Clickup issues deletion is needed before importing stories again'
      );
    }
  }, [clickupResultFieldId, importStatus, setErrorSnackbar]);

  useEffect(() => {
    if (!isLoggedInClickup) {
      const eventHandler = (e) => {
        if (e.detail === true) {
          setPlayer({ isLoggedInClickup: e.detail });
          handleClose('importFromClickup');
        } else {
          setErrorSnackbar(e.detail);
        }
        window.removeEventListener('clickup message', eventHandler);
      };
      window.addEventListener('clickup message', eventHandler);
    }
  }, []);

  const connectOrImport = () => {
    return isLoggedInClickup ? (
      <ImportFromClickupForm closeForm={() => handleCloseForm()} />
    ) : (
      importFromClickupHandler()
    );
  };

  return (
    manageIssues && (
      <div className={classes['issue-list']}>
        {importStatus === '' && connectOrImport()}
        {importStatus === 'error' && showErrorSnackbar()}
      </div>
    )
  );
};

export default ImportFromClickup;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'planning-poker-body': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'hidden',
    justifyContent: 'space-between',
  },

  'current-issue-container': {
    display: 'flex',
    height: '6%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  buttons: {
    borderRadius: '50px',
    width: '11rem',
    backgroundColor: '#00B4B2',
    color: '#ffffff',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    textTransform: 'capitalize',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: '#019997',
    },
  },

  'quick-result-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '14rem',
  },

  'quick-result-cards-panel': {
    backgroundColor: '#311ab5',
    boxShadow:
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  tooltip: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
  },

  'session-name': {
    color: '#c8cdff',
    textTransform: 'uppercase',
  },

  '@media screen and (max-width: 480px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 480px) and (max-width: 768px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 768px) and (max-width: 992px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 992px) and (max-width: 1200px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1200px) and (max-width: 1440p)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1440p)': {
    foo: 'bar',
  },
});

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'csv-checkbox-label': {
    fontFamily: 'Open Sans',
    fontWeight: '600',
    color: '#311ab5',
    fontSize: '15px',
    display: 'block',
  },

  'cancel-button': {
    color: '#311ab5',
    fontSize: '15px',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textTransform: 'capitalize',
  },

  'csv-file-selected-p': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize',
    color: '#311ab5',
    marginLeft: '1%',
  },

  'csv-file-selected': {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5% auto 0%',
    width: '605px',
  },
});

export default useStyles;

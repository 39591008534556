import * as React from 'react';

const defaultValues = {
  time: '',
  state: '',
  date: '',
  switch: true,
  input: '02:00',
};

function reducer(state, action) {
  return { ...state, ...action };
}

export const TimerContext = React.createContext(null);

export const TimerProvider = ({ timer, children }) => {
  const initialState = timer ? { ...timer, input: timer?.time } : defaultValues;
  const [timerState, dispatch] = React.useReducer(reducer, initialState);

  const value = React.useMemo(() => {
    return { timerState, dispatch };
  }, [timerState, dispatch]);

  return (
    <TimerContext.Provider value={value}>{children}</TimerContext.Provider>
  );
};

export const useTimerContext = () => {
  const value = React.useContext(TimerContext);
  if (value === null) {
    throw new Error('useTimerContext have to be used within a TimerProvider');
  }
  return value;
};

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  background: {
    background:
      "linear-gradient(79deg, #3848DD -4.88%, rgba(0, 18, 179, 0.7) 90.32%)",
    position: "absolute",
    width: "100%",
    flex: "1",
    backgroundSize: "100%",
    "-webkit-tap-highlight-color": "transparent",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 0,
  },
  children: {
    width: "100%",
    height: "100%",
    position: "relative",
    zIndex: "3",
    display: "flex",
    flexDirection: "column",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5rem",
      height: "0.5rem",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      "border-radius": "40px",
    },
  },
});

export default useStyles;

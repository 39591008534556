import * as React from "react";
import { PlayerContext } from "../contexts/playerContext";

export const usePlayerContext = () => {
  const context = React.useContext(PlayerContext);
  if (!context)
    throw new Error("usePlayer have to be used within a PlayerProvider");

  const setPlayer = (updatedPlayer) => {
    const currentPlayer = JSON.parse(localStorage.getItem("player"));
    const { _id, name, email, pictureURL, isLoggedInClickup } = updatedPlayer;
    const jsonPlayer = JSON.stringify({
      ...currentPlayer,
      ...(_id && { _id }),
      ...(name && { name }),
      ...(email && { email }),
      ...(pictureURL && { pictureURL }),
      ...(isLoggedInClickup && { isLoggedInClickup }),
    });
    localStorage.setItem("player", jsonPlayer);

    context.setPlayer((prevPlayer) => ({ ...prevPlayer, ...updatedPlayer }));
  };

  const clearPlayer = () => {
    localStorage.clear();
    context.clearPlayer();
  };

  return { player: context.player, setPlayer, clearPlayer };
};

import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Chip,
  InputLabel,
  MenuItem,
  Select,
  Breadcrumbs,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from '../MultipleIssues/MultipleIssuesStyles';
import GeneralButton from '../../../../../components/GeneralButton/GeneralButton';
import {
  GET_CLICKUP_SPACES,
  GET_CLICKUP_FOLDERS,
  GET_CLICKUP_FOLDERLESS_LISTS,
  GET_CLICKUP_LISTS,
  GET_CLICKUP_CUSTOM_FIELDS,
  IMPORT_CLICKUP_ISSUES,
  GET_CLICKUP_STATUSES,
} from '../../../../../services/clickup';
import { useSnackbarError } from '../../../../../hooks/useSnackbarError';
import { useSession } from '../../../../../contexts/sessionContext';
import { usePlayerContext } from '../../../../../hooks/usePlayerContext';
import { useModals } from '../../../../../contexts/modalsContext';
import IssueDialog from '../IssueDialog/IssueDialog';

const defaultElement = { name: '-' };
const defaultFormState = {
  space: defaultElement,
  folder: defaultElement,
  list: defaultElement,
  customField: defaultElement,
  statuses: [defaultElement],
};
const defaultMenuItem = (
  <MenuItem key={0} value={defaultElement}>
    -
  </MenuItem>
);
const defaultMenuItems = {
  spaces: [defaultMenuItem],
  folders: [defaultMenuItem],
  lists: [defaultMenuItem],
  customFields: [defaultMenuItem],
  statuses: [defaultMenuItem],
};

const menuItems = (elements) => [
  defaultMenuItem,
  ...Object.values(elements)[0].map((elem) => (
    <MenuItem key={elem.name} value={elem}>
      {elem.name}
    </MenuItem>
  )),
];

const ImportFromClickupForm = ({ closeForm }) => {
  const classes = useStyles();
  const { modalsState, toggleImportIssuesDialog } = useModals();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const {
    sessionState: { _id: sessionId },
  } = useSession();
  const {
    player: { isLoggedInClickup },
  } = usePlayerContext();
  const [formState, setFormState] = useState(defaultFormState);
  const [formItems, setFormItems] = useState(defaultMenuItems);
  const queryOptions = (elementType) => ({
    onCompleted: (data) =>
      setFormItems((prev) => ({ ...prev, [elementType]: menuItems(data) })),
    notifyOnNetworkStatusChange: true,
    onError: (error) => setErrorSnackbar(error?.message),
  });
  useQuery(GET_CLICKUP_SPACES, queryOptions('spaces'));
  const [getClickupFolders] = useLazyQuery(
    GET_CLICKUP_FOLDERS,
    queryOptions('folders')
  );
  const [getClickupFolderlessLists] = useLazyQuery(
    GET_CLICKUP_FOLDERLESS_LISTS,
    queryOptions('lists')
  );
  const [getClickupLists] = useLazyQuery(
    GET_CLICKUP_LISTS,
    queryOptions('lists')
  );
  const [getClickupCustomFields] = useLazyQuery(
    GET_CLICKUP_CUSTOM_FIELDS,
    queryOptions('customFields')
  );
  const [getClickupStatuses] = useLazyQuery(
    GET_CLICKUP_STATUSES,
    queryOptions('statuses')
  );
  const [importClickupIssues] = useMutation(IMPORT_CLICKUP_ISSUES, {
    onError: (error) => setErrorSnackbar(error?.message),
  });

  const onSpacesChange = ({ target: { value } }) => {
    setFormItems((prev) => ({ ...defaultMenuItems, spaces: prev.spaces }));
    setFormState({ ...defaultFormState, space: value });
    if (value.id) {
      const variables = { spaceId: value.id };
      getClickupFolders({ variables });
      getClickupFolderlessLists({ variables });
    }
  };

  const onFolderChange = ({ target: { value } }) => {
    setFormItems((prev) => ({
      ...prev,
      lists: [defaultMenuItem],
      customFields: [defaultMenuItem],
      statuses: [defaultMenuItem],
    }));
    setFormState((prev) => ({
      ...prev,
      folder: value,
      list: defaultElement,
      customField: defaultElement,
      statuses: [defaultElement],
    }));
    if (value.id) {
      getClickupLists({ variables: { folderId: value.id } });
    } else {
      getClickupFolderlessLists({ variables: { spaceId: formState.space.id } });
    }
  };

  const onListChange = ({ target: { value } }) => {
    setFormItems((prev) => ({
      ...prev,
      customFields: [defaultMenuItem],
      statuses: [defaultMenuItem],
    }));
    setFormState((prev) => ({
      ...prev,
      list: value,
      customField: defaultElement,
      statuses: [defaultElement],
    }));
    if (value.id) {
      const variables = { listId: value.id };
      getClickupCustomFields({ variables });
      getClickupStatuses({ variables });
    }
  };

  const onCustomFieldChange = ({ target: { value } }) => {
    setFormState((prev) => ({ ...prev, customField: value }));
  };

  const onStatusesChange = ({ target: { value } }) => {
    const newFormState = (prevFormState) => {
      let statuses;
      if (!value.length || value.at(-1).name === '-')
        statuses = [defaultElement];

      if (value[0].name === '-') statuses = value.slice(1);
      else statuses = value;

      return { ...prevFormState, statuses };
    };
    setFormState(newFormState);
  };

  const renderValue = (statuses) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {statuses.map(({ name }) => (
        <Chip key={name} label={name} />
      ))}
    </Box>
  );

  const onImport = (event) => {
    const canImport =
      formState.list?.id &&
      formState.customField?.id &&
      formState.statuses[0].name !== '-';
    canImport
      ? toggleImportIssuesDialog(event)
      : setErrorSnackbar('List, custom field name and status are required');
  };

  const importListFromClickup = async () => {
    const variables = {
      sessionId,
      listId: formState.list.id,
      fieldId: formState.customField.id,
      statuses: formState.statuses.map(({ name }) => name),
    };
    await importClickupIssues({ variables });
    toggleImportIssuesDialog();
    closeForm();
  };

  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    PaperProps: { style: { maxHeight: '25rem' } },
  };

  return isLoggedInClickup ? (
    <>
      <div className={classes['issue-card']}>
        <div className={classes['issue-card-head']}>
          <ArrowBackIcon
            onClick={closeForm}
            className={classes['issue-card-close-icon']}
          />
          <Typography
            variant="subtitle1"
            className={classes['issue-card-title']}
          >
            Import from ClickUp
          </Typography>
        </div>
        <InputLabel className={classes['input-label']} id="space-select-label">
          Space
        </InputLabel>
        <Select
          labelId="space-select-label"
          id="space-select"
          value={formState.space || defaultElement}
          label="space"
          onChange={onSpacesChange}
          MenuProps={{ ...menuProps }}
        >
          {formItems.spaces}
        </Select>
        <InputLabel className={classes['input-label']} id="folder-select-label">
          Folder
        </InputLabel>
        <Select
          labelId="folder-select-label"
          id="folder-select"
          value={formState.folder || defaultElement}
          label="folder"
          onChange={onFolderChange}
          disabled={formItems.folders.length < 2}
          MenuProps={{ ...menuProps }}
        >
          {formItems.folders}
        </Select>

        <InputLabel className={classes['input-label']} id="list-select-label">
          List
        </InputLabel>
        <Select
          labelId="list-select-label"
          id="list-select"
          value={formState.list || defaultElement}
          label="list"
          onChange={onListChange}
          disabled={formItems.lists.length < 2}
          MenuProps={{ ...menuProps }}
        >
          {formItems.lists}
        </Select>

        <InputLabel
          className={classes['input-label']}
          id="customfield-select-label"
        >
          Custom Field
        </InputLabel>
        <Select
          labelId="customfield-select-label"
          id="customfield-select"
          value={formState.customField || defaultElement}
          label="custom-field"
          onChange={onCustomFieldChange}
          disabled={formItems.customFields.length < 2}
          MenuProps={{ ...menuProps }}
        >
          {formItems.customFields}
        </Select>

        <InputLabel className={classes['input-label']} id="status-select-label">
          Status
        </InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          multiple
          value={formState.statuses || [defaultElement]}
          label="status-field"
          onChange={onStatusesChange}
          disabled={formItems.statuses.length < 2}
          renderValue={renderValue}
          MenuProps={{ ...menuProps }}
        >
          {formItems.statuses}
        </Select>

        <div className={classes['issue-buttons-container']}>
          <GeneralButton
            variant="contained"
            onClick={onImport}
            color="primary"
            label="Import"
          />
        </div>
      </div>
      <IssueDialog
        state={modalsState.importIssuesDialog}
        title="Are you sure you want to import tasks from this list?"
        toggle={toggleImportIssuesDialog}
        handler={importListFromClickup}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <span>{formState.space?.name}</span>
          {formState.folder?.id && <span>{formState.folder?.name}</span>}
          <span>{formState.list?.name}</span>
        </Breadcrumbs>
      </IssueDialog>
      {showErrorSnackbar()}
    </>
  ) : (
    closeForm()
  );
};

export default ImportFromClickupForm;

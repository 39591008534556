import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  "profile-picture-container": {
    width: "3.125rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "profile-picture": {
    borderRadius: "4rem",
    width: "100%",
  },

  "player-profile": {
    borderRadius: "4rem",
    width: "100%",
    transition: "scale 1s ease-in-out",
    "&:hover": {
      transform: "scale(1.06)",
    },
  },
});

export default useStyles;

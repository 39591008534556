import React, { useState } from 'react';
import { Box, Chip, IconButton, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSession } from '../../../../contexts/sessionContext';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';
import useStyles from './planningPokerBodyStyles';
import QuickResult from './QuickResult';

const titleLengthLimit = 60;

const PlanningPokerBodyHeader = () => {
  const classes = useStyles();
  const { player } = usePlayerContext();
  const {
    sessionState: { issues, currentIssue: currentIssueId, currentIssueIndex },
  } = useSession();
  const [showQuickResult, setShowQuickResult] = useState(false);

  const canManageIssues = player?.permissions?.manageIssues;
  const currentTitle = issues[currentIssueIndex]?.title;
  const titleTooltip =
    currentTitle?.length > titleLengthLimit ? currentTitle : '';
  const shortTitle =
    currentTitle?.length > titleLengthLimit
      ? `${currentTitle?.substring(0, titleLengthLimit).trim()}...`
      : currentTitle;

  return (
    <div className={classes['current-issue-container']}>
      {currentIssueId && (
        <Box display="flex" alignContent="center" alignItems="center">
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={titleTooltip}
            placement="top"
            arrow
          >
            <h4 style={{ color: 'white' }}>
              {`Story ${currentIssueIndex + 1}: ${shortTitle}`}
            </h4>
          </Tooltip>
          {canManageIssues && (
            <>
              <Tooltip title="Edit story points" arrow placement="top">
                <IconButton>
                  <MoreVertIcon
                    color="primary"
                    onClick={() => setShowQuickResult((prev) => !prev)}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Story points" arrow placement="top">
                <Chip
                  key="chip-result"
                  label={issues[currentIssueIndex]?.result}
                />
              </Tooltip>
            </>
          )}
        </Box>
      )}
      {showQuickResult && (
        <QuickResult
          onClose={() => {
            setShowQuickResult(false);
          }}
        />
      )}
    </div>
  );
};

export default React.memo(PlanningPokerBodyHeader);

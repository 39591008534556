import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Typography, IconButton } from '@material-ui/core';
import { Grid } from '@mui/material';
import { generatePath, Redirect, useHistory } from 'react-router-dom';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useModals } from '../../contexts/modalsContext';
import { routes } from '../../constants/routes';
import { usePlayerContext } from '../../hooks/usePlayerContext';
import { useOnError } from '../../hooks/useOnError';
import astronauts from '../../assets/dashboard/astronauts.png';
import Background from '../../components/Background/Background';
import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import { DELETE_SESSION, GET_SESSIONS } from '../../services/session';
import useStyles from './sessionsHistoryStyles';
import IssueDialog from '../PlanningPokerSession/components/IssuePanel/IssueDialog/IssueDialog';

const SessionsHistory = () => {
  const classes = useStyles();
  const { onError } = useOnError();
  const { player } = usePlayerContext();
  const history = useHistory();
  const { modalsState, toggleDeleteSessionDialog } = useModals();
  const [sessionIdToDelete, setSessionIdToDelete] = useState('');
  const [sessions, setSessions] = useState('');

  const { loading } = useQuery(GET_SESSIONS, {
    onCompleted: (data) => {
      if (data?.getSessions.length !== 0) {
        const result = [...data.getSessions];
        setSessions(result.reverse());
      }
    },
    onError,
    fetchPolicy: 'network-only',
  });

  const [deleteSession] = useMutation(DELETE_SESSION, {
    onCompleted: (data) => {
      const updatedSessions = [
        ...sessions.filter((sess) => sess.id !== data.deleteSession.id),
      ];
      setSessions(updatedSessions);
    },
    onError,
  });

  const redirectToDashboard = (sessionId) => {
    const dashboardPath = generatePath(routes.HISTORY_DASHBOARD, {
      sessionId,
    });
    history.push({
      pathname: dashboardPath,
      prevPath: history.location.pathname,
    });
  };

  const closeToggle = (event) => {
    setSessionIdToDelete('');
    toggleDeleteSessionDialog(event);
  };

  const openToggle = (event, sessionId) => {
    setSessionIdToDelete(sessionId);
    toggleDeleteSessionDialog(event);
  };

  const deleteSessionHandler = (event) => {
    deleteSession({ variables: { sessionId: sessionIdToDelete } });
    closeToggle(event);
  };

  const showHistoryPanel = () => {
    if (loading) return <Loading />;

    return (
      <Grid container item xs={9} className={classes.container}>
        <Grid item container xs={8} className={classes.panel}>
          <Grid item xs={12}>
            <Typography variant="h5">Planning Poker Sessions</Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes['sessions-container']}
          >
            {!sessions ? (
              <Grid item xs={12} className={classes['empty-list']}>
                <Typography variant="h4" gutterBottom="true">
                  🔍😥 Oh no! No sessions found...
                </Typography>
                <Typography variant="h6">Create or join one</Typography>
              </Grid>
            ) : (
              sessions.map((session, index) => (
                <Grid item key={session.id} className={classes['sessions-box']}>
                  <Button onClick={() => redirectToDashboard(session.id)}>
                    <Typography variant="subtitle1">
                      <Box
                        component="span"
                        fontWeight="fontWeightBold"
                        mr={1.5}
                      >
                        Session {index + 1}:
                      </Box>
                      {session.name}
                      {player._id === session.creator.id && (
                        <Box
                          component="span"
                          ml={1}
                          className={classes['sessions-crown']}
                        >
                          👑
                        </Box>
                      )}
                    </Typography>
                  </Button>
                  {player._id === session.creator.id && (
                    <IconButton
                      color="primary"
                      aria-label="Delete session"
                      onClick={(event) => openToggle(event, session.id)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
        <Grid item container xs={3.5}>
          <img
            className={classes.astronauts}
            src={astronauts}
            alt="astronauts"
          />
        </Grid>
      </Grid>
    );
  };

  return player._id ? (
    <Background>
      <Header />
      {showHistoryPanel()}
      <IssueDialog
        state={modalsState.deleteSessionDialog}
        title="Are you sure you want to delete this session?"
        toggle={closeToggle}
        handler={deleteSessionHandler}
      />
    </Background>
  ) : (
    <Redirect
      to={{
        pathname: routes.LOGIN_FORM,
        state: {
          type: 'error',
          message: 'You must be logged in.',
          prevPath: routes.SESSIONS_HISTORY,
        },
      }}
    />
  );
};

export default SessionsHistory;

import React from 'react';
import { Box } from '@material-ui/core';
import Player from '../../../../components/Player/Player';
import { useSession } from '../../../../contexts/sessionContext';

const REVEAL_MULTIPLIER = 1000;

const PlayersPanel = () => {
  const {
    sessionState: { players, issues, currentIssueIndex },
  } = useSession();

  const currentIssue = issues[currentIssueIndex];
  const activeObserverPlayers = players?.filter(
    (player) => player.active && !player.isObserver
  );

  return (
    <Box
      minHeight="25%"
      display="flex"
      justifyContent="center"
      sx={{ gap: '3rem' }}
    >
      {activeObserverPlayers.map(({ _id, name, pictureURL, index }) => {
        const voteIndex = currentIssue?.votes?.findIndex(
          (vote) => vote.playerId === _id
        );
        return (
          <Box key={index} width="3.5rem" display="flex">
            <Player
              name={name}
              playerId={_id}
              pictureURL={pictureURL}
              vote={currentIssue?.votes?.[voteIndex]?.value}
              revealDelay={voteIndex * REVEAL_MULTIPLIER}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default PlayersPanel;

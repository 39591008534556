import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'auto',
    justifyContent: 'center',

    '& ::-webkit-scrollbar': {
      width: '10px',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#201275',
      borderRadius: '1rem',
    },

    '& h1': {
      fontWeight: '300',
      fontSize: '6rem',
      color: '#EAECFE',
    },
    '& h6': {
      color: '#EAECFE',
    },
    '& h2': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: 'Roboto',
      fontSize: '1rem',
    },
  },

  container: {
    display: 'flex',
    justifyContent: 'center',
    gap: '3%',
    padding: '2.5rem',
    width: '90rem',
    height: '47rem',
    background:
      ' linear-gradient(74.75deg, #190E5A -4.58%, rgb(4, 0, 192) 105.18%)',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: '12px',

    '& .css-13i4rnv-MuiGrid-root': {
      flexDirection: 'column',
    },
  },

  'big-panel': {
    width: '35%',
    display: 'flex',
    alignItems: 'center',
    gap: '5%',
    padding: '2.5rem ',
    background: '#311AB5',
    borderRadius: '24px',
  },

  'players-container': {
    width: '100%',
    marginTop: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1.25rem',
    padding: '5%',
    overflow: 'auto',
  },
  'player-name-container': {
    padding: '0.25rem 1.25rem 0.25rem 1.25rem',
    background: '#EAECFE',
    borderRadius: '1rem',
  },

  'issues-container': {
    width: '100%',
    display: 'flex',
    gap: '1.75rem',
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  'issue-container': {
    paddingRight: '0.75rem',
    width: '100%',
    display: 'flex',
    gap: '2rem',
    flexDirection: 'row',
  },
  'issue-title': {
    width: '90%',
    minWidth: '1rem',
    '& h2': {
      color: '#EAECFE',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  'issue-result': {
    width: '2rem',
    marginLeft: 'auto',
    textAlign: 'center',
    background: '#EAECFE',
    borderRadius: '0.25rem',
  },

  'little-panels-container': {
    display: 'flex',
    gap: '10%',
  },

  'little-panel': {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    gap: '10%',
    padding: '2.5rem 4.25rem',
    background: '#311AB5',
    borderRadius: '24px',
  },

  planet: {
    flex: '1',
    display: 'flex',
  },

  '@media screen and (max-width: 480px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 480px) and (max-width: 768px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 768px) and (max-width: 992px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 992px) and (max-width: 1200px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1200px) and (max-width: 1440p)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1440p)': {
    foo: 'bar',
  },
});

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'number-color': {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    width: '100%',
    height: '100%',
  },

  'panel-card': {
    backgroundColor: 'white',
    '&:hover': {
      background: 'linear-gradient(0deg, #a485ff -19.23%, #e69eff 120.77%)',
    },
    '&:hover h5': {
      color: 'white',
    },
  },
  'panel-card-selected': {
    backgroundColor: '#08e7e4',
  },

  'center-player': {
    background: '#311ab5',
    '&.central': {
      width: '3.5rem',
      height: '5rem',
    },
  },
  'center-player-voted': {
    background: 'linear-gradient(0deg, #413566 -19.23%, #5c3f66 120.77%)',
    '&.central': {
      width: '3.5rem',
      height: '5rem',
    },
  },
  'center-player-revealed': {
    background: 'linear-gradient(0deg, #a485ff -19.23%, #e69eff 120.77%)',
    '&.central': {
      width: '3.5rem',
      height: '5rem',
    },
  },

  'consensus-card': {
    width: '2.5rem',
    height: '3.75rem',
    backgroundColor: 'white',
    '& h2': {
      color: '#311AB5',
    },
  },
});

export default useStyles;

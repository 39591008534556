import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cloud: {
    position: "absolute",
    height: (props) => props.height,
    width: (props) => props.width,
    top: (props) => props.positionY,
    left: (props) => props.positionX,
    transform: "translatey(0px)",
    animation: `$float ease-in-out infinite`,
    "animation-duration": (props) => props.animationDuration,
    zIndex: "2",
  },

  "@keyframes float": {
    "0%": {
      transform: "translatey(0px) ",
    },
    "50%": {
      transform: "translatey(-10px) ",
    },
    "100%": {
      transform: "translatey(0px) ",
    },
  },
});

export default useStyles;

import React from 'react';
import { IconButton } from '@material-ui/core';
import useStyles from './timerStyles';

const Icon = ({ onclick, icon }) => {
  const classes = useStyles();

  return (
    <IconButton
      onClick={onclick}
      className={classes['timer-icon-active']}
      size="small"
    >
      {icon}
    </IconButton>
  );
};

export default Icon;

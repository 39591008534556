import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'issue-buttons-container': {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px',
  },

  'issue-left-buttons': {
    width: '50%',
    flexDirection: 'row',
    '& button': {
      marginRight: '2%',
    },
  },

  'issue-right-buttons': {
    width: '50%',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& button': {
      marginRight: '3%',
    },
  },

  'issue-average-button': {
    padding: '5px',
    width: '39px',
    height: '39px',
    background: '#e961ff',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    '&:hover, &.MuiIconButton-root': {
      backgroundColor: '#e961ff',
    },
  },

  'issue-average-button-p': {
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.70rem',
    lineHeight: '23px',
    color: '#e961ff',
    background: 'white',
    borderRadius: '3px',
    width: '23px',
  },

  tooltip: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
  },

  '.tooltip.top .tooltip-arrow .tooltip-inner': {
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
  },
});

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'issue-card': {
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '2rem',

    position: 'relative',
    width: '100%',
    height: 'auto',

    background:
      'linear-gradient(0deg, rgba(0, 125, 255, 0.08), rgba(0, 125, 255, 0.08)), #EAECFE',

    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },

  'issue-card-head': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '0.45rem',
  },

  'issue-card-title': {
    fontFamily: 'Open Sans',
    color: 'rgba(0, 0, 0, 0.54)',
  },

  'issue-card-close': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  'issue-card-close-icon': {
    color: '#8898f2',
    cursor: 'pointer',
    marginRight: '0.1rem',
  },

  'issue-text-field': {
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: '1.4rem',
    lineHeight: '19px',
    color: '#383838',
  },

  'issue-buttons-container': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignItems: 'center',
  },

  'input-label': {
    marginTop: '3%',
  },

  'issue-card-list-item-text': { overflowWrap: 'break-word' },

  'textfield-class': {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
});

export default useStyles;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePlayerContext } from '../../hooks/usePlayerContext';
import { useAuth } from '../../hooks/useAuth';
import Loading from '../../components/Loading/Loading';
import Background from '../../components/Background/Background';
import Header from '../../components/Header/Header';

const Logout = () => {
  const { localLogout, googleLogout } = useAuth();
  const {
    player: { email: playerEmail },
  } = usePlayerContext();
  const { search } = useLocation();

  useEffect(() => {
    const comingFrom = new URLSearchParams(search).get('coming_from');
    if (playerEmail) {
      comingFrom === 'cognito' ? localLogout() : googleLogout();
    } else {
      localLogout();
    }
  }, []);

  return (
    <Background>
      <Header />
      <Loading />
    </Background>
  );
};

export default Logout;

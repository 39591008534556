import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  form: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center',
    background: '#ffffff',
    minWidth: '35.25rem',
    maxWidth: '57rem',
    height: (props) => (props.formSize === 'little' ? '35rem' : '40rem'),
    borderRadius: '24px',
    alignItems: 'center',
    marginTop: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '60px',
  },
  'form__mail-button': {
    width: '100%',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
    borderRadius: '10px',
    textAlign: 'center',
    marginTop: '40px',
  },
  'text-mail-botton': {
    padding: '15px',

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500%',
    fontSize: '20px',
    lineHeight: '23px',
    textTransform: ' none',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  form__line: {
    width: '100%',
    opacity: '0.6',
    border: '1px solid #323232;',
    flexGrow: '0',
  },
});

export default useStyles;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { Switch } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HistoryIcon from '@material-ui/icons/History';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import { UPDATE_SESSION_PLAYER } from '../../../../services/player';
import { useSession } from '../../../../contexts/sessionContext';
import ProfilePicture from '../../../ProfilePicture/ProfilePicture';
import useStyles from '../../headerStyles';

const UserMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const {
    sessionState: { _id: sessionId },
  } = useSession();
  const {
    player: { _id: playerId, name, isObserver, pictureURL },
    setPlayer,
  } = usePlayerContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [updateSessionPlayer] = useMutation(UPDATE_SESSION_PLAYER, {
    onError: (error) => setErrorSnackbar(error?.message),
  });

  const open = !!anchorEl;

  const onOpenMenuHandler = (event) => setAnchorEl(event.currentTarget);

  const onCloseMenuHandler = () => setAnchorEl(null);

  const onSwitchObserverHandler = () => {
    const variables = { sessionId, player: { isObserver: !isObserver } };
    setPlayer({ isObserver: !isObserver });
    updateSessionPlayer({ variables });
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onOpenMenuHandler}
      >
        <ProfilePicture
          playerId={playerId}
          pictureURL={pictureURL}
          variant="profile-picture"
        />
        <ExpandMoreIcon className={classes.icon} size="small" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseMenuHandler}
        className={classes['user-menu']}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <div className={classes['menu-username']}>Signed in as {name}</div>
        {sessionId && (
          <MenuItem onClick={onSwitchObserverHandler}>
            <Switch checked={isObserver} size="small" />
            Observer
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            history.push({
              pathname: routes.SESSIONS_HISTORY,
              prevPath: history.location.pathname,
            })
          }
        >
          <HistoryIcon size="small" />
          History
        </MenuItem>
        <MenuItem onClick={() => history.push(routes.LOGOUT)}>
          <ExitToAppIcon size="small" />
          Sign out
        </MenuItem>
      </Menu>
      {showErrorSnackbar()}
    </>
  );
};

export default UserMenu;

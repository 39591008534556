import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";

export const useSnackbarError = () => {
  const [snackbarError, setSnackbarError] = useState({
    open: false,
    message: "",
  });
  const onClose = () => setSnackbarError({ message: "", open: false });

  const setErrorSnackbar = (message) => {
    setSnackbarError({ open: true, message });
  };

  const showErrorSnackbar = () => {
    return (
      snackbarError.message && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackbarError.open}
          onClose={onClose}
          autoHideDuration={5000}
        >
          <Alert onClose={onClose} severity={"error"}>
            {snackbarError.message}
          </Alert>
        </Snackbar>
      )
    );
  };

  return { showErrorSnackbar, setErrorSnackbar };
};

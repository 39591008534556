import { getAvatar } from "../../services/Avatar";
import useStyles from "./ProfilePictureStyles";

const ProfilePicture = ({ pictureURL, playerId, variant }) => {
  const classes = useStyles();

  return (
    <div className={classes["profile-picture-container"]}>
      <img
        className={variant && classes[variant]}
        src={pictureURL || getAvatar(playerId)}
        onError={({ currentTarget }) => {
          currentTarget.onError = null;
          currentTarget.src = getAvatar(playerId);
        }}
        alt="Profile"
      ></img>
    </div>
  );
};

export default ProfilePicture;

import { useMutation } from "@apollo/client";
import { Hub, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { GOOGLE_LOGIN } from "../services/player";
import { usePlayerContext } from "./usePlayerContext";

export const useGoogleLogin = () => {
  const { player, player: { userToken }, setPlayer } = usePlayerContext();
  const [error, setError] = useState("");
  const [googleLogin, { reset }] = useMutation(GOOGLE_LOGIN, {
    onCompleted: ({ loginGoogle }) => {
      const { _id, name, email, pictureURL } = loginGoogle;
      setPlayer({ _id, name, email, pictureURL, loading: false });
    },
    onError: (error) => setError(error?.message),
  });

  const getCognitoUser = async () => {
    try {
      if (!userToken) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const token = cognitoUser.signInUserSession.idToken.jwtToken;
        setPlayer({ userToken: token });
      }
    } catch {
      setPlayer({ loading: false })
    }
  };

  useEffect(() => {
    if (userToken) {
      googleLogin({ variables: { token: userToken } });
      setPlayer({ loading: true });
    }
  }, [userToken]);

  useEffect(() => {
    const callback = c => c.listen.event === "signIn" && getCognitoUser()
    Hub.listen("auth", callback);
    getCognitoUser();

    return reset;
  }, []);

  return { player, error };
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    justifyContent: 'center',
    alignSelf: 'center',
    gap: '3%',
    padding: '2.5rem',
    maxHeight: '75%',
    background:
      ' linear-gradient(74.75deg, #190E5A -4.58%, rgb(4, 0, 192) 105.18%)',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: '12px',
  },

  panel: {
    alignItems: 'center',
    padding: '2.5rem ',
    background: '#311AB5',
    borderRadius: '24px',
    height: '100%',
    '& h5': {
      margin: '1.5rem',
      fontFamily: 'Open Sans, Times New Roman',
      fontWeight: '600',
      textAlign: 'center',
    },
  },

  astronauts: {
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: '24px',
  },

  'sessions-container': {
    gap: '1rem',
    paddingTop: '1.5rem',
    paddingRight: '1rem',
    height: '85%',
    overflowY: 'scroll',
  },

  'sessions-box': {
    paddingRight: '0.75rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background:
      'linear-gradient(74.75deg, #190E5A -4.58%, rgba(4, 0, 192, 0.6) 105.18%)',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    height: '2.5rem',
    borderRadius: '10px',
    '& h6': {
      color: '#b4e2f7',
    },
  },

  'empty-list': {
    padding: '7rem',
    alignItems: 'center',
    '& h4': {
      color: '#b4e2f7',
    },
    '& h6': {
      color: '#b4e2f7',
      textAlign: 'center',
      fontWeight: '600',
    },
  },

  'sessions-crown': {
    fontSize: '18px',
    position: 'relative',
    bottom: '1px',
  },
});

export default useStyles;

import {
  useLocation,
  useParams,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { useSubscription, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import PlanningPokerBody from './components/PlanningPokerBody/PlanningPokerBody';
import { useSession } from '../../contexts/sessionContext';
import { SESSION_SUBSCRIPTION, GET_SESSION } from '../../services/session';
import { usePlayerContext } from '../../hooks/usePlayerContext';
import { routes } from '../../constants/routes';
import Background from '../../components/Background/Background';
import { useSnackbarError } from '../../hooks/useSnackbarError';
import { useOnError } from '../../hooks/useOnError';
import Dashboard from '../Dashboard/PlanningPokerDashboard';
import GameSettings from '../GameSettings/GameSettings';
import Loading from '../../components/Loading/Loading';
import { onSubscribed, unsubscribe } from '../../services/graphql';
import { TimerProvider } from '../../contexts/timerContext';
import SessionName from './components/SessionName/SessionName';

const mapPage = () => {
  return {
    [routes.DASHBOARD]: () => <Dashboard />,
    [routes.GAME_SETTINGS]: () => <GameSettings />,
    [routes.PLANNING_POKER_SESSION]: () => <PlanningPokerBody />,
  };
};

const PlanningPokerSession = () => {
  const { showErrorSnackbar, setErrorSnackbar } = useSnackbarError();
  const { onError } = useOnError();
  const { sessionId } = useParams();
  const { path } = useRouteMatch();
  const location = useLocation();
  const {
    setSessionState,
    sessionState: { timer, name },
  } = useSession();
  const { player, setPlayer } = usePlayerContext();
  const [page, setPage] = useState(mapPage()[path]);
  const [pageLoading, setPageLoading] = useState(true);

  const [getSession] = useLazyQuery(GET_SESSION, {
    variables: { sessionId },
    onCompleted: ({ getSession: sessionData }) => {
      setSessionState({ ...sessionData });
      const sessionPlayer = sessionData.players?.find(
        (p) => p._id === player._id
      );
      const isCreator = sessionData.creator?._id === player?._id;

      setPlayer({ ...sessionPlayer, isCreator });
      setPageLoading(false);
    },
    onError,
    fetchPolicy: 'network-only',
  });
  const { data, error } = useSubscription(SESSION_SUBSCRIPTION, {
    variables: { sessionId },
    onSubscriptionData: ({ subscriptionData }) => {
      const sessionData = subscriptionData.data.session;
      setSessionState((prev) => ({ ...prev, ...sessionData }));
      const sessionPlayer = sessionData.players?.find(
        (p) => p._id === player._id
      );
      setPlayer(sessionPlayer);
    },
  });

  useEffect(() => {
    if (error) {
      onError();
    } else {
      const callbacks = {
        subscribed: getSession,
        closed: () => setErrorSnackbar("You're disconected!"),
      };
      onSubscribed(callbacks);
    }

    return () => {
      unsubscribe();
      setSessionState({});
    };
  }, [getSession, setSessionState, error]);

  useEffect(() => {
    setPage(mapPage()[path]);
  }, [path]);

  return player._id ? (
    <>
      <TimerProvider timer={timer}>
        <Background>
          <Header />
          {pageLoading ? (
            <Loading />
          ) : (
            <>
              <SessionName sessionName={name} />
              {page}
            </>
          )}
        </Background>
      </TimerProvider>
      {showErrorSnackbar()}
    </>
  ) : (
    !data?.session?._id && (
      <Redirect
        to={{
          pathname: routes.LOGIN_FORM,
          state: {
            type: 'error',
            message: 'You must be logged in to access the session.',
            prevPath: location.pathname,
          },
        }}
      />
    )
  );
};

export default PlanningPokerSession;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'planning-cards-panel': {
    width: '100%',
    height: '100%',
    backgroundColor: '#311ab5',
    boxShadow:
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  'issue-cards-panel': {
    width: '100%',
    height: '5rem',
    backgroundColor: '#311ab5',
    boxShadow:
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  'cards-panel': {
    height: '100%',
    overflow: 'auto',
  },

  'card-button': {
    minWidth: '0px',
    padding: '0px 0px',
    width: '100%',
    height: '100%',
    '& .MuiButton-label': {
      height: '100%',
    },
  },

  'planning-card': {
    width: '3.5rem',
    height: '5rem',
    margin: '1rem',
  },

  'issue-little-card': {
    width: '1.4rem',
    height: '2rem',
    margin: '0.2rem',
    '& h5': {
      fontSize: '0.9rem',
      fontWeight: '600',
    },
  },

  'issue-big-card': {
    width: '2rem',
    height: '2.5rem',
    margin: '0.3rem',
    '& h5': {
      fontSize: '0.9rem',
      fontWeight: '600',
    },
  },
});

export default useStyles;

import { gql } from '@apollo/client';

export const UPDATE_TIMER = gql`
  mutation updateTimer($sessionId: String!, $timerBody: UpdateTimerInput!) {
    updateTimer(sessionId: $sessionId, timerBody: $timerBody) {
      time
      state
      date
      switch
    }
  }
`;

export default UPDATE_TIMER;

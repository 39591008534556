import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import CardItem from '../CardItem/CardItem';
import useStyles from './generalCardsPanelStyles';

const GeneralCardsPanel = ({
  scale,
  currentIssue,
  cardSelected,
  setCardSelected,
  variant,
}) => {
  // TODO move voting logic here

  const classes = useStyles();

  const issueStyle = scale?.length < 9 ? 'issue-big-card' : 'issue-little-card';
  const variantStyle = variant === 'planning' ? 'planning-card' : issueStyle;

  useEffect(() => {
    setCardSelected('');
  }, [currentIssue]);

  const listCards = scale?.map((cardNumber) => {
    return (
      <div key={cardNumber} className={classes[variantStyle]}>
        <Button
          onClick={() => setCardSelected(cardNumber)}
          className={classes['card-button']}
        >
          <CardItem
            number={cardNumber}
            variant={
              cardNumber !== cardSelected ? 'panel-card' : 'panel-card-selected'
            }
          />
        </Button>
      </div>
    );
  });

  return (
    <div className={classes[`${variant}-cards-panel`]}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes['cards-panel']}
      >
        {listCards}
      </Grid>
    </div>
  );
};

export default GeneralCardsPanel;

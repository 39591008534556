import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './consensusStyles';

const ResultsDescription = ({
  strategy,
  result,
  agreement,
  agreementLevel,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="h5"
          className={classes['results-title']}
          style={{ fontWeight: 600 }}
        >
          <span>Summary</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" className={classes['results-title']}>
          <span className={classes.span}>{`Card's ${strategy}:`}</span>
          <span>{result}</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" className={classes['results-title']}>
          <span className={classes.span}>Agreement:</span>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              value={100}
              thickness={6}
              size={55}
              className={classes['background-circle']}
            />
            <CircularProgress
              variant="determinate"
              value={agreement}
              thickness={6}
              size={55}
              className={classes[`circle-${agreementLevel}`]}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="subtitle2"
                className={classes['results-title']}
              >{`${agreement}%`}</Typography>
            </Box>
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ResultsDescription;

import Header from "../../components/Header/Header";
import Form from "./components/LoginPlayerForm/LogInPlayerForm";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { validateName } from "../../helpers/Validate";
import Background from "../../components/Background/Background";
import { usePlayerContext } from "../../hooks/usePlayerContext";
import useStyles from "../defaultPageStyles";
import { useAuth } from "../../hooks/useAuth";
import { Redirect } from "react-router";
import { useSnackbarError } from "../../hooks/useSnackbarError";
import { routes } from "../../constants/routes";

const LogInPlayer = () => {
  const classes = useStyles();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const { state: routeState } = useLocation();
  const history = useHistory();
  const { player: { _id: playerId }, setPlayer } = usePlayerContext();
  const { createPlayer, error } = useAuth();

  const createPlayerHandler = async (player) => {
    if (!validateName(player.name)) {
      setErrorSnackbar(`Player name ${player.name ? "invalid" : "required"}`);
      return;
    }
    createPlayer(player);
  };

  const googleLoginPopup = () => {
    const prevPath = `prev_path=${routeState?.prevPath || routes.HOME}`;
    prevPath && window.open(
      `${process.env.REACT_APP_BASE_URL}${routes.LOGIN}?${prevPath}`,
      "googleLogin",
      "popup, width=700, height=700"
    );
  };

  useEffect(() => {
    const eventHandler = ({ detail }) => {
      setPlayer(detail);
      history.push(routeState?.prevPath)
    }
    window.addEventListener("google_login_message", eventHandler);

    return () => window.removeEventListener("google_login_message", eventHandler);
  }, []);

  useEffect(() => {
    setErrorSnackbar(error);
  }, [error]);

  useEffect(() => {
    routeState?.type === "error" && setErrorSnackbar(routeState?.message);
  }, [routeState]);

  return (
    <>
      {playerId ? (
        <Redirect to={{ pathname: routeState?.prevPath || routes.HOME }} />
      ) : (
        <>
          <Background>
            <Header />
            <div className={classes.mainContainer}>
              <Form onSubmit={createPlayerHandler} onGoogleLogin={googleLoginPopup} />
            </div>
          </Background>
          {showErrorSnackbar()}
        </>
      )}
    </>
  );
};

export default LogInPlayer;

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGoogleLogin } from '../../hooks/useGoogleLogin';
import Background from '../../components/Background/Background';
import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import { routes } from '../../constants/routes';
import { useAuth } from '../../hooks/useAuth';

const Login = () => {
  const { player, error } = useGoogleLogin();
  const { googleAuth } = useAuth();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (player._id) {
      const event = new CustomEvent('google_login_message', { detail: player });
      window.opener.dispatchEvent(event);
      window.close();
    }
    if (error) {
      const prevPath = new URLSearchParams(location.search).get('prev_path');
      const pushState = { type: 'error', message: error, prevPath };
      history.push(routes.LOGIN_FORM, pushState);
    }
    if (location.search) {
      const prevPath = new URLSearchParams(location.search).get('prev_path');
      prevPath && googleAuth();
    }
  }, [error, player]);

  return (
    <Background>
      <Header />
      <Loading />
    </Background>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useSession } from '../../../../contexts/sessionContext';
import { useDebounce } from '../../../../hooks/useDebounce';
import PlayersPanel from '../PlayersPanel/PlayersPanel';
import IssuesPanel from '../IssuePanel/IssuesPanel';
import useStyles from './planningPokerBodyStyles';
import PlanningPokerBodyHeader from './PlanningPokerBodyHeader';
import PlanningPokerBodyMiddleZone from './PlanningPokerBodyMiddleZone';
import PlanningPokerBodyBottom from './PlanningPokerBodyBottom';
import { UPDATE_SESSION_PLAYER } from '../../../../services/player';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';

const REVEAL_MULTIPLIER = 1000;

const PlanningPokerBody = () => {
  const classes = useStyles();
  const {
    sessionState: {
      _id: sessionId,
      revealed,
      issues,
      currentIssue: currentIssueId,
      creator,
      players,
    },
    setSessionState,
  } = useSession();
  const { player: currentPlayer } = usePlayerContext();
  const [updateSessionPlayer] = useMutation(UPDATE_SESSION_PLAYER);
  const [snackbar, setSnackbar] = useState(false);
  const onClose = () => setSnackbar(false);

  const currentIssueIndex = issues?.findIndex(
    (issue) => issue._id === currentIssueId
  );
  const votesCount = issues?.[currentIssueIndex]?.votes.length;
  const delayedReveal = useDebounce(revealed, votesCount * REVEAL_MULTIPLIER);

  useEffect(() => {
    setSessionState((prev) => ({ ...prev, delayedReveal, currentIssueIndex }));
  }, [delayedReveal, currentIssueIndex, setSessionState]);

  useEffect(() => {
    if (!creator?.active) {
      const activePlayers = players?.filter((player) => player.active);
      const adminPlayer = activePlayers.find(
        ({ permissions }) => permissions.revealCards && permissions.manageIssues
      );
      if (!adminPlayer && activePlayers[0]._id === currentPlayer._id) {
        const permissions = { revealCards: true, manageIssues: true };
        const update = async () => {
          await updateSessionPlayer({
            variables: {
              sessionId,
              player: { permissions },
            },
          });
        };
        update();
        setSnackbar(true);
      }
    }
  }, [players]);

  return (
    <>
      <div className={classes['planning-poker-body']}>
        <PlanningPokerBodyHeader />
        <PlayersPanel />
        <PlanningPokerBodyMiddleZone />
        <PlanningPokerBodyBottom />
      </div>
      <IssuesPanel />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar}
        onClose={onClose}
        autoHideDuration={5000}
      >
        <Alert onClose={onClose} severity="success">
          {`Now you're the session's host!`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PlanningPokerBody;

import { useSession } from '../contexts/sessionContext';
import {
  calculateAgreementLevel,
  calculateAgreementValue,
  calculateResult,
  countCardVotes,
} from '../helpers/consensus';

const getVotes = (issue, scale) => {
  if (scale === 'T-shirts') {
    return issue?.votes?.map((v) => v.value);
  }
  return issue?.votes?.map((v) => {
    const isNumber = !Number.isNaN(parseInt(v.value, 10));
    return isNumber ? parseInt(v.value, 10) : v.value;
  });
};

export const useConsensus = () => {
  const {
    sessionState: { issues, scale, strategy, currentIssueIndex },
  } = useSession();

  const checkConsensus = () => {
    const issue = issues[currentIssueIndex];
    const votes = getVotes(issue, scale);

    if (votes?.length) {
      const result = calculateResult(strategy, votes);
      const agreement = calculateAgreementValue(votes);
      const agreementLevel = calculateAgreementLevel(agreement);
      const votesCount = countCardVotes(votes);
      return { result, agreement, agreementLevel, votesCount };
    }

    return {};
  };

  return { checkConsensus };
};

export default useConsensus;

import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box } from '@material-ui/core';
import useStyles from './loadingStyles';
import Cloud from '../../assets/background/cloud.svg';
import Rocket from '../../assets/rocket.svg';

const Loading = () => {
  const classes = useStyles();

  return (
    <Box className={`${classes['loading-container']}`}>
      <Box className={`${classes.loading}`}>
        <img
          className={`${classes['loading-cloud-small']}`}
          src={Cloud}
          alt="Cloud"
        />
        <img
          className={`${classes['loading-rocket']}`}
          src={Rocket}
          alt="Rocket"
        />
        <img
          className={`${classes['loading-cloud-big']}`}
          src={Cloud}
          alt="Cloud"
        />
        <LinearProgress className={`${classes.progress}`} />
        <p>LOADING...</p>
      </Box>
    </Box>
  );
};

export default Loading;

import { gql } from "@apollo/client";

export const ADD_PLAYER = gql`
  mutation addPlayerToSession($sessionId: String!) {
    addPlayerToSession(sessionId: $sessionId) {
      _id
    }
  }
`;

export const CREATE_PLAYER = gql`
  mutation createPlayer($playerBody: CreatePlayerInput!) {
    createPlayer(playerBody: $playerBody) {
      _id
      name
    }
  }
`;

export const UPDATE_SESSION_PLAYER = gql`
  mutation updateSessionPlayer(
    $sessionId: String!
    $player: UpdateSessionPlayerInput!
  ) {
    updateSessionPlayer(sessionId: $sessionId, player: $player) {
      _id
    }
  }
`;

export const GET_TOKEN_PLAYER = gql`
  query getTokenPlayer {
    getTokenPlayer {
      _id
      name
    }
  }
`;

export const LOG_OUT = gql`
  mutation logOut {
    logOut
  }
`;

export const GOOGLE_LOGIN = gql`
  mutation loginGoogle($token: String!) {
    loginGoogle(token: $token) {
      _id
      name
      email
      pictureURL
    }
  }
`;

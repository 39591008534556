import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  'timer-menu': {
    marginTop: '0.6%',
    '& ul': {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '1.5rem',
      marginBottom: '1rem',
    },
    '& li': {
      display: 'flex',
      width: '80%',
      paddingLeft: '0%',
      paddingRight: '0%',
      justifyContent: 'flex-start',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      color: '#5D73F5',
    },
  },
  'timer-menu-textfield': {
    marginBottom: '1rem',
    width: '75%',
  },
  'timer-menu-icon': {
    marginRight: '6%',
    marginLeft: '6%',
    color: '#5D73F5',
  },

  'timer-icon-active': {
    color: '#FFFFFF',
  },

  'timer-icon-inactive': {
    color: '#00B0FF',
  },

  'rocket-container': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '3%',
    right: '45%',
    left: '45%',
    zIndex: '2',
    '& h5': {
      fontSize: '30px',
      backgroundColor: '#E961FF',
      paddingLeft: '6%',
      paddingRight: '6%',
      borderRadius: '6px',
      zIndex: '2',
      whiteSpace: 'nowrap',
    },
  },

  rocket: {
    animation: '$flight 2.5s ease-in infinite',
    '& img': {
      marginRight: '0',
    },
  },

  '@keyframes flight': {
    from: {
      transform: 'translate(0, 0) ',
    },
    to: {
      transform: 'translate(120%, -120%) ',
    },
  },

  'button-primary': {
    borderRadius: '50px',
    minWidth: '80px',
    backgroundColor: 'white',
    color: '#00B0FF',
    fontFamily: 'Open Sans',
    fontStyle: 'regular',
    fontWeight: '600',
    textTransform: 'capitalize',
    marginRight: '0.5rem',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: '#311ab5',
    },
    '&:disabled': {
      backgroundColor: 'white',
      color: '#00B0FF',
    },
  },

  'button-secondary': {
    borderRadius: '50px',
    minWidth: '80px',
    backgroundColor: '#E961FF',
    color: 'white',
    fontFamily: 'Open Sans',
    fontStyle: 'regular',
    fontWeight: '600',
    textTransform: 'capitalize',
    marginRight: '0.5rem',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: '#9C27B0',
    },
    '&:disabled': {
      backgroundColor: '#E961FF',
      color: 'white',
    },
  },

  'button-blinking': {
    borderRadius: '50px',
    minWidth: '80px',
    color: 'white',
    animation: `$blink 1.3s ease-in-out infinite`,
    fontFamily: 'Open Sans',
    fontStyle: 'regular',
    fontWeight: '600',
    textTransform: 'capitalize',
    marginRight: '0.5rem',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: '#962921',
    },
    '&:disabled': {
      color: 'white',
    },
  },

  '@keyframes blink': {
    '0%': {
      backgroundColor: '#f44336',
    },
    '50%': {
      backgroundColor: '#fa7b7b',
    },
    '100%': {
      backgroundColor: '#f44336',
    },
  },

  'timer-tooltip': {
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: '#5D73F5',
    backgroundColor: 'white',
  },
});

export default useStyles;

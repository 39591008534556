import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'form__submit-button': {
    width: '100%',
    '& button': {
      width: '100%',
      height: '2.75rem',
      borderRadius: '50px',
      boxShadow:
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
      fontFamily: 'Open Sans',
      fontWeight: '600',
      textTransform: 'capitalize',
      fontSize: '20px',
    },
  },
});

export default useStyles;

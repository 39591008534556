import { TextField, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from '../../../LoginPlayer/components/LoginPlayerForm/formStyles';
import TextInput from '../../../../components/TextInput/TextInput';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';
import { scales } from '../../../../constants/scales';
import SubmitFormButton from '../../../../components/SubmitFormButton/SubmitFormButton';

const defaultFormValues = {
  name: '',
  scale: 'Fibonacci',
  strategy: 'Majority',
  showCards: 'OnlyMe',
};

const scalesNames = Object.keys(scales);

const CreateSessionForm = ({ onSubmit, loading }) => {
  const classes = useStyles({ formSize: 'little' });
  const [formState, setFormState] = useState(defaultFormValues);
  const { player } = usePlayerContext();

  const onSubmitHandler = (e) => {
    e.preventDefault();
    onSubmit(formState);
  };

  return (
    <form onSubmit={onSubmitHandler} className={classes.form}>
      <div key="1" className="title">
        <h4>Welcome</h4>
      </div>
      <TextInput
        name="Session Name"
        stateName="name"
        value={formState.name}
        setValue={setFormState}
        placeholder={`${player.name}'s session`}
      />
      <TextField
        select
        fullWidth
        margin="normal"
        label="Scale"
        variant="outlined"
        onChange={(e) => setFormState({ ...formState, scale: e.target.value })}
        defaultValue={formState.scale}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {scalesNames.map((scaleName) => (
          <MenuItem key={scaleName} value={scaleName}>
            {scaleName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        fullWidth
        margin="normal"
        label="Consensus Strategy"
        variant="outlined"
        onChange={(e) =>
          setFormState({ ...formState, strategy: e.target.value })
        }
        defaultValue={formState.strategy}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {formState.scale !== 'T-shirts' && (
          <MenuItem value="Average">Average</MenuItem>
        )}
        <MenuItem value="Majority">Majority</MenuItem>
      </TextField>
      <TextField
        select
        fullWidth
        margin="normal"
        label="Who can show cards?"
        variant="outlined"
        onChange={(e) =>
          setFormState({ ...formState, showCards: e.target.value })
        }
        defaultValue={formState.showCards}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        <MenuItem value="OnlyMe">Only me</MenuItem>
        <MenuItem value="Everyone">Everyone</MenuItem>
      </TextField>
      <SubmitFormButton loading={loading} label="Let's vote" />
    </form>
  );
};

export default CreateSessionForm;

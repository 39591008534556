import imageAvatarPerezoso from "../assets/playerAvatar/squad.svg";
import imageAvatarAvocado from "../assets/playerAvatar/avocado.svg";
import imageAvatarAlien from "../assets/playerAvatar/alien.svg";
import imageAvatarBear from "../assets/playerAvatar/bear.svg";
import imageAvatarPanda from "../assets/playerAvatar/panda.svg";
import imageAvatarBatman from "../assets/playerAvatar/batman.svg";
import imageAvatarCactus from "../assets/playerAvatar/cactus.svg";
import imageAvatarCup from "../assets/playerAvatar/cup.svg";
import imageAvatarRain from "../assets/playerAvatar/rain.svg";
import imageAvatarSheep from "../assets/playerAvatar/sheep.svg";
import imageAvatarZombie from "../assets/playerAvatar/zombie.svg";
import imageAvatarAnime from "../assets/playerAvatar/anime.svg";
import imageAvatarEinstein from "../assets/playerAvatar/einstein.svg";
import imageAvatarFighter from "../assets/playerAvatar/fighter.svg";
import imageAvatarMarilyn from "../assets/playerAvatar/marilyn.svg";
import imageAvatarOzzy from "../assets/playerAvatar/ozzy.svg";
import imageAvatarPencil from "../assets/playerAvatar/pencil.svg";
import imageAvatarSanta from "../assets/playerAvatar/santa.svg";
import imageAvatarHeisenberg from "../assets/playerAvatar/heisenberg.svg";
import imageAvatarNinja from "../assets/playerAvatar/ninja.svg";
import imageAvatarSquad from "../assets/playerAvatar/squad.svg";

const avatars = [
  imageAvatarAnime,
  imageAvatarAvocado,
  imageAvatarAlien,
  imageAvatarPerezoso,
  imageAvatarBatman,
  imageAvatarPanda,
  imageAvatarBear,
  imageAvatarCactus,
  imageAvatarCup,
  imageAvatarRain,
  imageAvatarSheep,
  imageAvatarZombie,
  imageAvatarMarilyn,
  imageAvatarSanta,
  imageAvatarEinstein,
  imageAvatarPencil,
  imageAvatarOzzy,
  imageAvatarFighter,
  imageAvatarHeisenberg,
  imageAvatarNinja,
  imageAvatarSquad,
];

export const getAvatar = (playerId) => {
  if (playerId) {
    const hash = playerId
      .split("")
      .reduce((total, char) => total + char.charCodeAt());
    const index = hash % avatars.length;
    const avatar = avatars[index];
    return avatar;
  }
};

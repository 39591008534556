import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'issue-list-add-issue': {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '605px',
    height: '5%',
    margin: '5% auto 0%',
    padding: '5% 5% 5% 1%',

    background:
      'linear-gradient(0deg,rgba(0, 125, 255, 0.08),rgba(0, 125, 255, 0.08))',
    borderRadius: '4px',
  },

  'issue-list-add-issue-icon': {
    color: '#311ab5',
  },

  'issue-list-add-issue-button': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '26px',

    letterSpacing: '0.46px',
    textTransform: 'capitalize',

    color: '#311ab5',
  },

  'panel-message': {
    fontFamily: 'Roboto',
    color: 'black',
    fontSize: '30px',
    height: '18rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '@media screen and (max-width: 480px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 480px) and (max-width: 768px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 768px) and (max-width: 992px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 992px) and (max-width: 1200px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1200px) and (max-width: 1440p)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1440p)': {
    foo: 'bar',
  },
});

export default useStyles;

import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { UPDATE_SESSION } from '../../../../services/session';
import { UPDATE_ISSUE } from '../../../../services/issue';
import { useConsensus } from '../../../../hooks/useConsensus';
import { useSession } from '../../../../contexts/sessionContext';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import { useTimerContext } from '../../../../contexts/timerContext';
import GeneralButton from '../../../../components/GeneralButton/GeneralButton';
import Consensus from '../Consensus/Consensus';
import useStyles from './planningPokerBodyStyles';

const PlanningPokerBodyMiddleZone = () => {
  const classes = useStyles();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const onError = (error) => setErrorSnackbar(error?.message);
  const { checkConsensus } = useConsensus();
  const { player } = usePlayerContext();
  const {
    timerState: { input: timerInput },
    dispatch,
  } = useTimerContext();
  const {
    sessionState: {
      _id: sessionId,
      currentIssue,
      revealed,
      issues,
      delayedReveal,
      currentIssueIndex,
      timer,
    },
  } = useSession();
  const [updateSession, { loading: loadingSession }] = useMutation(
    UPDATE_SESSION,
    { onError }
  );
  const [updateIssue, { loading: loadingIssue }] = useMutation(UPDATE_ISSUE, {
    onError,
  });
  const [openTooltip, setOpenTooltip] = useState(false);

  const { result } = checkConsensus();
  const votesCount = issues?.[currentIssueIndex]?.votes.length;
  const canRevealCards = player?.permissions?.revealCards;
  const showRevealButton = currentIssue && canRevealCards && !revealed;
  const showConsensus = currentIssue && revealed && delayedReveal && result;

  const votingButton = useMemo(() => {
    if (player.permissions.manageIssues && !currentIssue && issues.length) {
      const allIssuesAreVoted = issues.every(
        (element) => element.result !== '-'
      );
      if (allIssuesAreVoted) return '';

      const areSomeVotes = issues.some((element) => element.result !== '-');
      return areSomeVotes ? 'Continue Voting' : 'Start Voting';
    }
    return '';
  }, [currentIssue, issues, player.permissions.manageIssues]);

  const revealVotesHandler = () => {
    if (votesCount) {
      const variables = { sessionId, sessionBody: { revealed: true } };
      updateSession({ variables });
      dispatch({ state: 'inactive' });
    }
  };

  const voteHandler = async () => {
    const issueToVote = issues.find((element) => element.result === '-');
    await updateSession({
      variables: {
        sessionId,
        sessionBody: { revealed: false, currentIssue: issueToVote._id },
      },
    });
    timer?.switch &&
      dispatch({
        time: timerInput,
        state: 'active',
        date: Date.now(),
      });
    await updateIssue({
      variables: {
        sessionId,
        issueId: issueToVote._id,
        deleteVotes: true,
      },
    });
  };

  const handleTooltipOpen = () => !votesCount && setOpenTooltip(true);

  const showRevealOrStartVotingButtons = () => {
    if (showRevealButton) {
      return (
        <Tooltip
          title="One vote is needed to reveal votes"
          arrow
          placement="top"
          open={openTooltip}
          onClose={() => setOpenTooltip(false)}
          onOpen={handleTooltipOpen}
        >
          <div>
            <GeneralButton
              variant="contained"
              color="secondary"
              size="large"
              label="Reveal votes"
              onClick={revealVotesHandler}
            />
          </div>
        </Tooltip>
      );
    }

    if (votingButton) {
      return (
        <Button
          variant="contained"
          size="large"
          className={classes.buttons}
          onClick={voteHandler}
        >
          {loadingSession || loadingIssue ? (
            <CircularProgress size="1.50rem" thickness={5} />
          ) : (
            votingButton
          )}
        </Button>
      );
    }

    return null;
  };

  return (
    <Box
      minHeight="30%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {showRevealOrStartVotingButtons()}
      {showConsensus && <Consensus />}
      {showErrorSnackbar()}
    </Box>
  );
};

export default React.memo(PlanningPokerBodyMiddleZone);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  'issue-panel-container': {
    width: '27rem',
    overflowY: 'scroll',
    paddingTop: '2rem',
  },

  'issue-panel': {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },

  'issue-panel-bar': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    background:
      'linear-gradient(79deg, #3848dd 0%, rgba(0, 18, 179, 0.7) 98.67%)',
  },

  'issue-import-button': {
    textTransform: 'capitalize',
  },

  'issue-menu-item-disabled': {
    fontSize: '14px',
  },

  'issue-panel-button': {
    textTransform: 'capitalize',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: '1.2rem',
  },

  'buttons-container': {
    position: 'sticky',
    bottom: '0',
    width: '100%',
    padding: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    background: 'linear-gradient(180deg, transparent, white 70%)',
    boxShadow: '10px 8px white,-10px 8px white',
  },

  'import-menu': {
    marginTop: '0.5rem',
    '& li': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      color: '#5D73F5',
    },
  },

  '@media screen and (max-width: 480px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 480px) and (max-width: 768px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 768px) and (max-width: 992px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 992px) and (max-width: 1200px)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1200px) and (max-width: 1440p)': {
    foo: 'bar',
  },
  '@media screen and (min-width: 1440p)': {
    foo: 'bar',
  },
});

export default useStyles;

import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GeneralButton from '../../../GeneralButton/GeneralButton';
import useStyles from '../../headerStyles';
import { routes } from '../../../../constants/routes';

const ReturnButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const returnToPrevPath = () => {
    history.push(history.location.prevPath || routes.HOME);
  };

  return (
    <div className={classes['header__buttonMargin']}>
      <GeneralButton
        startIcon={<ArrowBackIcon />}
        variant="contained"
        color="primary"
        size="large"
        label="Return"
        onClick={returnToPrevPath}
      />
    </div>
  );
};

export default ReturnButton;

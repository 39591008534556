import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Tooltip,
  Button,
  Container,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useMutation } from '@apollo/client';
import { useModals } from '../../../../contexts/modalsContext';
import { useSession } from '../../../../contexts/sessionContext';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import {
  DELETE_ALL_ISSUES,
  UPDATE_SESSION,
} from '../../../../services/session';
import IssuesList from './IssuesList/IssuesList';
import useStyles from './issuePanelStyles';
import IssueDialog from './IssueDialog/IssueDialog';
import ImportTasksMenu from './ImportTasksMenu';
import CSVIssues from './CSVIssues/CSVIssues';
import ImportFromClickup from './ImportFromClickup/ImportFromClickup';

const IssuesPanel = () => {
  const classes = useStyles();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const { modalsState, toggleDrawer, toggleDeleteIssuesDialog } = useModals();
  const [currentView, setCurrentView] = useState('');
  const [disabled, setDisabled] = useState(true);
  const {
    sessionState: { issues, _id: sessionId, currentIssue },
  } = useSession();
  const { player } = usePlayerContext();
  const [deleteAllIssues] = useMutation(DELETE_ALL_ISSUES, {
    variables: { sessionId },
    onError: (error) => setErrorSnackbar(error?.message),
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    variables: { sessionId, sessionBody: { currentIssue: '' } },
    onError: (error) => setErrorSnackbar(error?.message),
  });

  const deleteAllIssuesHandler = async (e) => {
    currentIssue && (await updateSession());
    await deleteAllIssues();
    toggleDeleteIssuesDialog(e);
  };

  useEffect(() => {
    issues?.length ? setDisabled(false) : setDisabled(true);
  }, [issues]);

  const playerHasPermissions = player?.permissions?.manageIssues;

  const toggleCurrentView = useCallback((viewName = '') => {
    setCurrentView(viewName);
  }, []);

  return (
    <Drawer
      anchor="right"
      open={modalsState.issueDrawer}
      onClose={toggleDrawer}
    >
      <Box className={classes['issue-panel']}>
        <Box className={classes['issue-panel-bar']}>
          <Button
            onClick={() => setCurrentView('')}
            color="primary"
            className={classes['issue-panel-button']}
          >
            Stories
          </Button>
          <Box>
            {playerHasPermissions && (
              <>
                <ImportTasksMenu handleOnMenuItemClick={toggleCurrentView} />
                <Tooltip title="Delete all stories">
                  <IconButton
                    color="primary"
                    aria-label="Delete all stories"
                    onClick={toggleDeleteIssuesDialog}
                    disabled={disabled}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <IconButton onClick={toggleDrawer} color="primary">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Container className={classes['issue-panel-container']}>
          {currentView === '' && <IssuesList />}
          {currentView === 'importCsv' && (
            <CSVIssues handleClose={toggleCurrentView} />
          )}
          {currentView === 'importFromClickup' && (
            <ImportFromClickup handleClose={toggleCurrentView} />
          )}
        </Container>
      </Box>

      <IssueDialog
        state={modalsState.deleteAllIssuesDialog}
        title="Are you sure you want to delete all stories?"
        toggle={toggleDeleteIssuesDialog}
        handler={deleteAllIssuesHandler}
      />
      {showErrorSnackbar()}
    </Drawer>
  );
};

export default IssuesPanel;

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import useStyles from './consensusStyles';

const ConfettiLabel = () => {
  const classes = useStyles();

  const confettiColors = [
    '#a864fd',
    '#29cdff',
    '#78ff44',
    '#ff718d',
    '#fdff6a',
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <ConfettiExplosion
          force={0.6}
          duration={5000}
          particleCount={200}
          floorHeight={1600}
          floorWidth={1600}
          colors={confettiColors}
        />
      </Grid>
      <Grid item>
        <Box className={`${classes['consensus-title']}`}>
          <Typography variant="h5">Consensus!</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(ConfettiLabel);

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PlanningPokerButtons from './PlanningPokerButtons/PlanningPokerButtons';
import ErrorButtons from './ErrorButtons/ErrorButtons';
import useStyles from '../headerStyles';
import HomeButtons from './HomeButtons/HomeButtons';
import { routes } from '../../../constants/routes';
import UserMenu from './UserMenu/UserMenu';
import { usePlayerContext } from '../../../hooks/usePlayerContext';
import ReturnButton from './ReturnButton/ReturnButton';

const mapPagetoIcons = () => {
  return {
    [routes.HISTORY_DASHBOARD]: () => <ReturnButton />,
    [routes.DASHBOARD]: () => <ReturnButton />,
    [routes.PLANNING_POKER_SESSION]: () => <PlanningPokerButtons />,
    [routes.HOME]: () => <HomeButtons />,
    [routes.GAME_SETTINGS]: () => <ReturnButton />,
    [routes.SESSIONS_HISTORY]: () => <ReturnButton />,
    [routes.ERROR]: () => <ErrorButtons />,
  };
};

const RightItems = () => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const { player } = usePlayerContext();
  const [rightItems, setRightItems] = useState(mapPagetoIcons()[path]);

  useEffect(() => {
    setRightItems(mapPagetoIcons()[path]);
  }, [path]);

  return (
    <div className={classes.right}>
      <div className={classes.header__button}>
        {rightItems}
        {player._id && <UserMenu />}
      </div>
    </div>
  );
};

export default RightItems;

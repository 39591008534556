import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { validateName } from '../../../../helpers/Validate';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import useStyles from '../../../LoginPlayer/components/LoginPlayerForm/formStyles';
import { useSession } from '../../../../contexts/sessionContext';
import { scales } from '../../../../constants/scales';
import SubmitFormButton from '../../../../components/SubmitFormButton/SubmitFormButton';

const scalesNames = Object.keys(scales);

const GameSettingsForm = ({ onSubmit, loading }) => {
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const classes = useStyles();
  const {
    sessionState: { name, scale, strategy, players, creator },
    permissionsList,
  } = useSession();
  const [formState, setFormState] = useState({
    name: '',
    scale: '',
    strategy: '',
    revealCards: [],
    manageIssues: [],
  });

  const mappedPlayers = players.reduce(
    (acc, cur) => ({ ...acc, [cur._id]: cur.name }),
    {}
  );

  const playerItems = [{ name: 'Everybody', _id: 'everybody' }, ...players].map(
    (player) => (
      <MenuItem key={player.name} value={player._id}>
        {player.name}
      </MenuItem>
    )
  );

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (!validateName(formState.name)) {
      setErrorSnackbar('Invalid session name');
      return;
    }

    const playersUpdated = players?.reduce((acc, currentPlayer) => {
      const isCreator = currentPlayer._id === creator._id;
      const playerUpdated = {
        permissions: {
          ...currentPlayer.permissions,
          revealCards:
            formState.revealCards.includes(currentPlayer._id) || isCreator,
          manageIssues:
            formState.manageIssues.includes(currentPlayer._id) || isCreator,
        },
      };
      return [...acc, { ...playerUpdated }];
    }, []);

    const sessionUpdated = {
      name: formState.name,
      scale: formState.scale,
      strategy: formState.strategy,
    };
    onSubmit(sessionUpdated, playersUpdated);
  };

  const onPermissionChange = ({ target: { name, value } }) => {
    const everybody = value.find((v) => v === 'everybody');
    const selectedPlayers = everybody ? Object.keys(mappedPlayers) : value;
    setFormState((prev) => ({ ...prev, [name]: selectedPlayers }));
  };

  const renderValue = (selected) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((playerId) => (
        <Chip key={playerId} label={mappedPlayers[playerId]} />
      ))}
    </Box>
  );

  useEffect(
    () =>
      setFormState((prev) => ({
        ...prev,
        name,
      })),
    []
  );

  useEffect(() => {
    if (scale) {
      setFormState((prev) => ({
        ...prev,
        scale,
        strategy,
        revealCards: permissionsList.revealCards,
        manageIssues: permissionsList.manageIssues,
      }));
    }
  }, [scale, strategy, permissionsList]);

  return (
    <form onSubmit={onSubmitHandler} className={classes.form}>
      <h4 className="title">Session settings</h4>

      <TextField
        name="Session Name"
        stateName="name"
        label="Session Name"
        variant="outlined"
        fullWidth
        value={formState.name}
        onChange={(e) =>
          setFormState((prev) => ({ ...prev, name: e.target.value }))
        }
        placeholder="My session"
      />

      <TextField
        select
        fullWidth
        margin="normal"
        label="Scale"
        variant="outlined"
        value={formState.scale}
        onChange={(e) =>
          setFormState((prev) => ({ ...prev, scale: e.target.value }))
        }
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: { maxHeight: '15rem' },
            },
          },
        }}
      >
        {scalesNames.map((scaleName) => (
          <MenuItem key={scaleName} value={scaleName}>
            {`${scaleName} (${scales[scaleName].join(', ')})`}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        fullWidth
        margin="normal"
        label="Consensus Strategy"
        variant="outlined"
        value={formState.strategy}
        onChange={(e) =>
          setFormState((prev) => ({ ...prev, strategy: e.target.value }))
        }
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {formState.scale !== 'T-shirts' && (
          <MenuItem value="Average">Average</MenuItem>
        )}
        <MenuItem value="Majority">Majority</MenuItem>
      </TextField>
      <TextField
        select
        id="can-reveal-cards"
        name="revealCards"
        fullWidth
        margin="normal"
        label="Who can reveal cards?"
        variant="outlined"
        SelectProps={{
          multiple: true,
          value: formState.revealCards,
          onChange: onPermissionChange,
          renderValue,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: { maxHeight: '15rem' },
            },
          },
        }}
      >
        {playerItems}
      </TextField>
      <TextField
        select
        id="can-manage-issues"
        name="manageIssues"
        fullWidth
        margin="normal"
        label="Who can manage stories?"
        variant="outlined"
        SelectProps={{
          multiple: true,
          value: formState.manageIssues,
          onChange: onPermissionChange,
          renderValue,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: { maxHeight: '15rem' },
            },
          },
        }}
      >
        {playerItems}
      </TextField>
      <SubmitFormButton label="Save" loading={loading} />
      {showErrorSnackbar()}
    </form>
  );
};

export default GameSettingsForm;

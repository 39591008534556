import { useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSession } from '../contexts/sessionContext';
import { unsubscribe } from '../services/graphql';
import {
  LOG_OUT,
  UPDATE_SESSION_PLAYER,
  CREATE_PLAYER,
} from '../services/player';
import { usePlayerContext } from './usePlayerContext';
import { COGNITO_LOGOUT_URL } from '../constants/URLs';
import { routes } from '../constants/routes';

export const useAuth = () => {
  const history = useHistory();
  const [error, setError] = useState('');
  const {
    player: { loading },
    clearPlayer,
    setPlayer,
  } = usePlayerContext();
  const {
    sessionState: { _id: sessionId },
  } = useSession();
  const [localLogoutMutation] = useMutation(LOG_OUT, {
    onCompleted: () => setPlayer({ loading: false }),
  });
  const [updateSessionPlayer] = useMutation(UPDATE_SESSION_PLAYER);
  const [createPlayerMutation] = useMutation(CREATE_PLAYER, {
    onCompleted: ({ createPlayer }) => setPlayer(createPlayer),
    onError: (createPlayerError) => setError(createPlayerError?.message),
  });

  const createPlayer = async (playerBody) => {
    await createPlayerMutation({ variables: { playerBody } });
  };

  const googleAuth = async (redirectURI) => {
    const options = { provider: 'Google', customState: redirectURI };
    await Auth.federatedSignIn(options);
  };

  const localLogout = () => {
    const variables = { sessionId, player: { active: false } };
    sessionId && updateSessionPlayer({ variables });
    clearPlayer();
    localLogoutMutation();
    unsubscribe();
    history.push(routes.HOME);
  };

  const googleLogout = () => {
    setPlayer({ loading: true });
    window.location.href = COGNITO_LOGOUT_URL;
  };

  return {
    loading,
    googleLogout,
    localLogout,
    createPlayer,
    googleAuth,
    error,
  };
};

export default useAuth;

import GeneralButton from "../../../GeneralButton/GeneralButton";
import { useHistory } from "react-router-dom";
import { usePlayerContext } from "../../../../hooks/usePlayerContext";
import { routes } from "../../../../constants/routes";
import { useAuth } from "../../../../hooks/useAuth";

const HomeButtons = () => {
  const history = useHistory();
  const { player } = usePlayerContext();
  const { loading } = useAuth();

  const onClick = () => history.push(routes.LOGIN_FORM, { prevPath: routes.HOME });

  return (
    !loading &&
    !player._id && (
      <GeneralButton
        variant={"contained"}
        color={"primary"}
        size={"large"}
        label={"Login"}
        onClick={onClick}
      />
    )
  );
};

export default HomeButtons;

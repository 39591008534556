import useStyles from "./backgroundStyles";
import Cloud from "./Cloud/Cloud";

const Background = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.background}`}>
      <Cloud
        height={"15rem"}
        width={"20rem"}
        positionY={"51%"}
        positionX={"80%"}
        animationDuration={"8s"}
      />
      <Cloud
        height={"10rem"}
        width={"15rem"}
        positionY={"50%"}
        positionX={"-3%"}
        animationDuration={"5s"}
      />
      <div className={classes.children}>{children}</div>
    </div>
  );
};

export default Background;

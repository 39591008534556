import React from 'react';
import {
  Redirect,
  useParams,
  useHistory,
  generatePath,
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import GameSettingsForm from './components/GameSettingsForm/GameSettingsForm';
import { usePlayerContext } from '../../hooks/usePlayerContext';
import { routes } from '../../constants/routes';
import useStyles from '../defaultPageStyles';
import { UPDATE_SESSION } from '../../services/session';
import { useSnackbarError } from '../../hooks/useSnackbarError';

const GameSettings = () => {
  const classes = useStyles();
  const { player } = usePlayerContext();
  const { sessionId } = useParams();
  const history = useHistory();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const path = generatePath(routes.PLANNING_POKER_SESSION, {
    sessionId,
  });
  const [updateSession, { loading }] = useMutation(UPDATE_SESSION, {
    onCompleted: () => history.push(path),
    onError: (error) => setErrorSnackbar(error?.message),
  });
  const canAccessSettingsForm = player._id && player.permissions.manageIssues;

  const onSubmitHandler = async (form, players) => {
    await updateSession({
      variables: {
        sessionId,
        sessionBody: form,
        playersPermissions: players,
      },
    });
  };

  return canAccessSettingsForm ? (
    <div className={classes.mainContainer}>
      <GameSettingsForm onSubmit={onSubmitHandler} loading={loading} />
      {showErrorSnackbar()}
    </div>
  ) : (
    <Redirect to={{ pathname: path }} />
  );
};

export default GameSettings;

import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      dark: '#311AB5',
      light: '#8898F2',
      background:
        'linear-gradient(0deg, rgba(0, 125, 255, 0.08), rgba(0, 125, 255, 0.08)), #EAECFE',
      border: '#7D8FF8',
      constrastText: '#00B0FF',
    },
    secondary: {
      main: '#E961FF',
      dark: '#9C27B0',
      light: '#EA80FC',
      background: 'rgba(213, 0, 249, 0.08)',
      border: '#E040FB',
      constrastText: '#FFFFFF',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#00B0FF',
      },
      containedSecondary: {
        color: '#FFFFFF',
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: '#311AB5',
          border: 'none',
        },
        border: 'none',
        background: '#00B0FF',
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      },
    },
    MuiTab: {
      textColorPrimary: {
        '&$selected': {
          color: '#00B0FF',
        },
      },
    },
    MuiLinearProgress: {
      barColorPrimary: {
        background: 'linear-gradient(261.44deg, #08E7E4, #5D73F5)',
      },
      colorPrimary: {
        backgroundColor: 'rgb(0,0,0,0)',
      },
      root: {
        height: '1.813rem',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
  },
});

export default theme;

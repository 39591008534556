import * as React from "react";

export const SessionContext = React.createContext(null);

export const SessionProvider = ({ children }) => {
  const [sessionState, setSessionState] = React.useState({});

  const permissionsList = sessionState.creator
    ? Object.keys(sessionState?.creator?.permissions).reduce((acc, current) => {
        return {
          ...acc,
          [current]: [...sessionState?.players]
            .filter((player) => player?.permissions[current])
            .map((player) => player._id),
        };
      }, {})
    : [];

  const value = {
    sessionState,
    setSessionState,
    permissionsList,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const useSession = () => {
  const value = React.useContext(SessionContext);
  if (value === null) {
    throw new Error("useSession have to be used within a SessionProvider");
  }
  return value;
};

import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Box, Container, Tooltip } from '@material-ui/core';
import { UPDATE_ISSUE } from '../../../../services/issue';
import { UPDATE_SESSION } from '../../../../services/session';
import { useSession } from '../../../../contexts/sessionContext';
import { useTimerContext } from '../../../../contexts/timerContext';
import { useSnackbarError } from '../../../../hooks/useSnackbarError';
import { usePlayerContext } from '../../../../hooks/usePlayerContext';
import useStyles from './planningPokerBodyStyles';

const ActionButtons = () => {
  const classes = useStyles();
  const { setErrorSnackbar, showErrorSnackbar } = useSnackbarError();
  const onError = (error) => setErrorSnackbar(error?.message);
  const {
    timerState: { input: timerInput },
    dispatch,
  } = useTimerContext();
  const { player } = usePlayerContext();
  const {
    sessionState: {
      issues,
      currentIssue: currentIssueId,
      _id: sessionId,
      timer,
      revealed,
      delayedReveal,
      currentIssueIndex,
    },
  } = useSession();
  const [updateIssue] = useMutation(UPDATE_ISSUE, { onError });
  const [updateSession] = useMutation(UPDATE_SESSION, { onError });
  const [openTooltip, setOpenTooltip] = useState(false);

  const showButtons =
    currentIssueId && player?.permissions?.manageIssues && delayedReveal;
  const isLastIssue = issues?.length === currentIssueIndex + 1;
  const showContinueButton = useMemo(() => {
    if (revealed && currentIssueId) {
      const isResultSet = issues && issues[currentIssueIndex]?.result !== '-';
      const areIssuesToVote = issues.some((element) => element.result === '-');
      if (isLastIssue || !isResultSet || !areIssuesToVote) return false;
      return true;
    }
    return false;
  }, [revealed, currentIssueId, issues, currentIssueIndex]);

  const handleUpdate = async (issueId) => {
    await updateSession({
      variables: {
        sessionId,
        sessionBody: { revealed: false, currentIssue: issueId },
      },
    });
    timer?.switch &&
      dispatch({
        time: timerInput,
        state: 'active',
        date: Date.now(),
      });
    await updateIssue({
      variables: {
        sessionId,
        issueId,
        deleteVotes: true,
      },
    });
  };

  const handleRevote = () => handleUpdate(currentIssueId);

  const getNextTask = () => issues.find((element) => element.result === '-');

  const handleVoteNextTask = async () => {
    const nextIssue = getNextTask();
    handleUpdate(nextIssue._id);
  };
  const handleTooltipOpen = () => !showContinueButton && setOpenTooltip(true);

  return (
    <Container>
      {showButtons && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '3rem',
            pb: '50px',
          }}
        >
          <Button
            variant="contained"
            size="large"
            className={classes.buttons}
            onClick={handleRevote}
          >
            Revote
          </Button>
          {!isLastIssue && (
            <Tooltip
              title="Set Story Result"
              arrow
              placement="top"
              open={openTooltip}
              onClose={() => setOpenTooltip(false)}
              onOpen={handleTooltipOpen}
            >
              <div>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.buttons}
                  onClick={handleVoteNextTask}
                  disabled={!showContinueButton}
                >
                  Vote Next Story
                </Button>
              </div>
            </Tooltip>
          )}
        </Box>
      )}
      {showErrorSnackbar()}
    </Container>
  );
};

export default ActionButtons;

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, useParams } from 'react-router-dom';
import Background from '../../components/Background/Background';
import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import { useOnError } from '../../hooks/useOnError';
import { usePlayerContext } from '../../hooks/usePlayerContext';
import { GET_SESSION } from '../../services/session';
import DashboardContent from './DashboardContent';
import { routes } from '../../constants/routes';
import SessionName from '../PlanningPokerSession/components/SessionName/SessionName';

const HistoryDashboard = () => {
  const { onError } = useOnError();
  const { sessionId } = useParams();
  const {
    player: { _id: playerId },
  } = usePlayerContext();

  const [pageLoading, setPageLoading] = useState(true);

  const { data: session } = useQuery(GET_SESSION, {
    variables: { sessionId },
    onCompleted: () => setPageLoading(false),
    onError,
    fetchPolicy: 'network-only',
  });

  const { scale, players, issues, name } = { ...session?.getSession };

  return playerId ? (
    <Background>
      <Header />
      {pageLoading ? (
        <Loading />
      ) : (
        <>
          <SessionName sessionName={name} />
          <DashboardContent scale={scale} players={players} issues={issues} />
        </>
      )}
    </Background>
  ) : (
    <Redirect
      to={{
        pathname: routes.LOGIN_FORM,
        state: {
          type: 'error',
          message: 'You must be logged in.',
          prevPath: routes.HISTORY_DASHBOARD,
        },
      }}
    />
  );
};

export default HistoryDashboard;

import GeneralButton from "../../../GeneralButton/GeneralButton";
import useStyles from "../../headerStyles";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../constants/routes";

const ErrorButtons = () => {
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    history.push(routes.HOME);
  };

  return (
    <div className={classes.header__buttonMargin}>
      <GeneralButton
        variant={"contained"}
        color={"primary"}
        size={"large"}
        label={"Home"}
        onClick={onClick}
      />
    </div>
  );
};

export default ErrorButtons;

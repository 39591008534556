import CardItem from "../CardItem/CardItem";
import { useSession } from "../../contexts/sessionContext";
import clsx from "clsx";
import { useDebounce } from "../../hooks/useDebounce";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import Tooltip from "@material-ui/core/Tooltip";
import useStyles from "./playerStyles";
import { Box } from "@material-ui/core";

const Player = ({ playerId, name, pictureURL, vote = null, revealDelay }) => {
  const classes = useStyles();
  const {
    sessionState: { revealed },
  } = useSession();
  const delayedReveal = useDebounce(revealed, revealDelay);

  return (
    <div className={classes.player}>
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        title={name}
        arrow
        placement="top"
      >
        <Box sx={{ my: 2 }}>
          <ProfilePicture
            playerId={playerId}
            pictureURL={pictureURL}
            variant={"player-profile"}
          />
        </Box>
      </Tooltip>
      <div
        className={clsx(
          classes["flip-card"],
          vote && revealed && delayedReveal && "click"
        )}
      >
        <div className={classes.front}>
          <CardItem variant={vote ? "center-player-voted" : "center-player"} />
        </div>
        <div className={classes.back}>
          <CardItem number={vote} variant={"center-player-revealed"} />
        </div>
      </div>
    </div>
  );
};
export default Player;

import { useHistory } from 'react-router-dom';
import React from 'react';
import Header from '../../components/Header/Header';
import useStyles from './homeStyles';
import GeneralButton from '../../components/GeneralButton/GeneralButton';
import Rocket from '../../assets/rocket.svg';
import { routes } from '../../constants/routes';
import Background from '../../components/Background/Background';

const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Background>
      <Header />
      <div className={`${classes.container}`}>
        <h1 className={`${classes.title}`}>Planiketa</h1>
        <GeneralButton
          variant="contained"
          color="primary"
          size="large"
          label="Create Planning Poker Session"
          onClick={() => history.push(routes.CREATE_SESSION)}
        />
      </div>
      <div className={`${classes.rocket}`}>
        <img src={Rocket} alt="Rocket" />
      </div>
    </Background>
  );
};

export default Home;

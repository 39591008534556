import { useHistory } from 'react-router-dom';
import { usePlayerContext } from './usePlayerContext';
import { routes } from '../constants/routes';

export const useOnError = () => {
  const history = useHistory();
  const { clearPlayer } = usePlayerContext();

  const onError = (error) => {
    switch (error?.graphQLErrors[0].extensions.code) {
      case 'FORBIDDEN':
        history.push(routes.HOME);
        clearPlayer();
        break;
      case 'BAD_USER_INPUT':
        history.push(routes.HOME);
        break;
      default:
        history.push(`/${routes.ERROR}`);
        break;
    }
  };

  return { onError };
};

export default useOnError;

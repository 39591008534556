import * as React from "react";

export const PlayerContext = React.createContext(null);

export const PlayerProvider = ({ children }) => {
  const lsPlayer = JSON.parse(localStorage.getItem("player"));
  const emptyPlayer = {
    _id: "",
    name: "",
    email: "",
    pictureURL: "",
    isLoggedInClickup: false,
    isCreator: false,
    isObserver: false,
    loading: false,
    permissions: { manageIssues: false, revealCards: false },
  };
  const [player, setPlayer] = React.useState({ ...emptyPlayer, ...lsPlayer });

  const clearPlayer = () => setPlayer(emptyPlayer);

  const provided = { player, setPlayer, clearPlayer };

  return (
    <PlayerContext.Provider value={provided}>{children}</PlayerContext.Provider>
  );
};

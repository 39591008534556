import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import useStyles from './issuePanelStyles';

const ImportTasksMenu = ({ handleOnMenuItemClick }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !!anchorEl;

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleMenuItemClick = (menuItemOption) => {
    handleOnMenuItemClick(menuItemOption);
    handleCloseMenu();
  };

  return (
    <>
      <Button
        color="primary"
        size="small"
        startIcon={<SystemUpdateAltIcon />}
        className={classes['issue-import-button']}
        aria-controls="import-task-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        Import stories
      </Button>
      <Menu
        id="import-task-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className={classes['import-menu']}
        PaperProps={{ style: { width: '110px' } }}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleMenuItemClick('importCsv')}>
          CSV
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('importFromClickup')}>
          Clickup
        </MenuItem>
      </Menu>
    </>
  );
};

export default ImportTasksMenu;

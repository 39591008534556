import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    borderRadius: '50px',
    minWidth: '80px',
    width: (props) => props.width,
    height: (props) => props.height,
    fontFamily: 'Open Sans',
    fontStyle: 'regular',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
});

export default useStyles;
